.o-cc-ca-d {
	border: 1px solid #969696;
	border-radius: 2.4rem;
	box-shadow: 0px 0px 8px 2px rgba(0, 0, 0, 0.1);
	height: 100%;

	&__content {
		padding: 5.5rem 3.5rem;

		@include breakpoint(1023px down) {
			padding: 3.5rem;
		}
	}

	&__icon {
		margin-bottom: 2.5rem;
	}

	&__text {
		h1,
		h2,
		h3 {
			@extend %o-title-inter-semibold-24;
			margin-bottom: 2.5rem;
		}

		ul {
			li {
				color: $color-charcoal;
				line-height: 1.6;
				margin-left: 1.5rem;
				margin-bottom: 0;
				list-style-position: outside;
			
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}
