.column-content-ca {
	$this: &;

	&.col-gap-45 {
		$gap: 2.7rem;

		#{$this}__row {
			margin-left: -$gap;
			margin-right: -$gap;

			@include breakpoint(1023px down) {
				margin-left: 0;
				margin-right: 0;
			}
		}

		#{$this}__col {
			padding-left: $gap;
			padding-right: $gap;
			margin-bottom: #{$gap * 2};

			@include breakpoint(1023px down) {
				padding-left: 0;
				padding-right: 0;
				margin-bottom: 3.5rem;
			}

			&.col-4 {
				$gap: 1.3rem;
				padding-left: $gap;
				padding-right: $gap;
				margin-bottom: #{$gap * 2};

				.o-cc-ca-ic1 {
					padding: 3rem;

					&__title {
						margin-bottom: 2.7rem;
					}

					&__title-text {
						font-size: 2.2rem;

						p {
							font-size: 1.6rem;
						}
					}
				}
			}
		}
	}

	&.enable-mobile-slider {
		#{$this}__row {
			@include breakpoint(1023px down) {
				display: none;
			}
		}
	}

	&__col {
		&.col-2 {
			width: 50%;

			@include breakpoint(1023px down) {
				width: 100%;
			}
		}

		&.col-3 {
			width: 33.3333%;

			@include breakpoint(1023px down) {
				width: 100%;
			}
		}

		&.col-4 {
			width: 25%;

			@include breakpoint(1023px down) {
				width: 100%;
			}
		}

		&.col-5 {
			width: 20%;

			@include breakpoint(1279px down) {
				width: 25%;
			}

			@include breakpoint(1023px down) {
				width: 100%;
			}
		}
	}

	&__slider {
		@include breakpoint(1024px up) {
			display: none;
		}
	}

	&__swiper {
		padding-bottom: 3.8rem;
	}

	&__swiper-slide {
		height: auto;

		.o-cc-ca-ic1 {
			box-sizing: border-box;
			height: 100%;
		}
	}

	&__swiper-pagination {
		bottom: 0;

		.swiper-pagination-bullet {
			background-color: $color-charcoal;
			width: 1rem;
			height: 1rem;
		}
	}

	&__swiper-button-next,
	&__swiper-button-prev {
		margin-top: -3.8rem;

		&::after {
			color: $color-charcoal;

			@include breakpoint(767px down) {
				font-size: 2.4rem;
			}
		}
	}

	$swiper-button-distance: 3%;
	&__swiper-button-prev {
		left: $swiper-button-distance;
	}

	&__swiper-button-next {
		right: $swiper-button-distance;
	}
}
