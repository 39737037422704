.two-column-content-d {
	$this: &;

	position: relative;

	&__row {
		$gap: 2.5rem;

		margin-left: -$gap;
		margin-right: -$gap;

		@include breakpoint(1023px down) {
			margin-left: 0;
			margin-right: 0;
		}
	}

	&__col {
		$gap: 2.5rem;

		padding-left: $gap;
		padding-right: $gap;

		@include breakpoint(1023px down) {
			padding-left: 0;
			padding-right: 0;
			margin-bottom: 3rem;
		}

		&:last-child {
			margin-bottom: 0;
		}

		&.width-55p {
			@include breakpoint(1024px up) {
				width: 55%;
			}
		}

		&.width-50p {
			@include breakpoint(1024px up) {
				width: 50%;
			}
		}

		&.width-45p {
			@include breakpoint(1024px up) {
				width: 45%;
			}
		}
	}

	&__content {
		margin-bottom: 3rem;

		&.border-radius-15 {
			border-radius: 1.5rem;
		}

		&.form-center-submit-button {
			.hs-submit {
				text-align: center;
			}
		}

		&.color-text-white {
			.hs-form-field {
				label {
					color: $color-white-1;

					span {
						&.hs-form-required {
							color: inherit !important;
						}
					}
				}

				input,
				select,
				textarea {
					background-color: transparent;
					border: 0.2rem solid $color-white-1;
					color: $color-white-1;
				}

				.btn-group {
					.multiselect {
						border-color: $color-white-1;
						color: $color-white-1;
					}
				}

				select {
					background-image: url("../../assets/images/chevron-down-white.svg");
				}
			}

			.hs-form-checkbox {
				> label {
					> span {
						color: $color-white-1;
					}
				}
			}

			.submitted-message {
				color: $color-white-1;
			}

			h1,
			h2,
			h3,
			h4,
			h5,
			h6,
			p {
				color: $color-white-1;
			}

			ol,
			ul {
				li {
					color: $color-white-1;
				}
			}

			.formcraft-css {
				.fc-form {
					.customText-cover {
						p {
							color: $color-white-1 !important;
							font-size: 1.8rem !important;
						}
					}

					.field-cover {
						// LABEL
						> span {
							> span {
								> span {
									color: $color-white-1;
								}

								&.sub-label {
									b,
									span {
										color: $color-white-1;
									}
								}
							}
						}
					}

					.form-element {
						// is required (*)
						&.is-required-true .field-cover .main-label > span::after {
							color: $color-white-1 !important;
						}

						.form-element-html {
							input[type="color"],
							input[type="date"],
							input[type="datetime-local"],
							input[type="datetime"],
							input[type="email"],
							input[type="month"],
							input[type="number"],
							input[type="password"],
							input[type="search"],
							input[type="tel"],
							input[type="text"],
							input[type="time"],
							input[type="url"],
							input[type="week"],
							select,
							textarea {
								background-color: transparent;
								border: 2px solid $color-white-1;
								color: $color-white-1;
							}
						}

						// FILE UPLOAD
						.fileupload-cover.field-cover {
							.button-file {
								span {
									color: $color-white-1;
								}
							}
						}

						// CHECKBOX
						.checkbox-cover > div label {
							color: $color-white-1;
						}
					}
				}
			}
		}

		&.font-size-16 {
			p {
				font-size: 1.6rem;
			}

			ol,
			ul {
				li {
					font-size: 1.6rem;
				}
			}
		}

		&.font-size-18 {
			p {
				font-size: 1.8rem;
			}

			ol,
			ul {
				li {
					font-size: 1.8rem;
				}
			}
		}

		&.font-size-24 {
			p {
				font-size: 2.4rem;
			}

			ol,
			ul {
				li {
					font-size: 2.4rem;
				}
			}
		}

		&.padding-h-55 {
			padding-left: 5.5rem;
			padding-right: 5.5rem;

			@include breakpoint(767px down) {
				padding-left: 3rem;
				padding-right: 3rem;
			}
		}

		&.padding-v-55 {
			padding-top: 5.5rem;
			padding-bottom: 5.5rem;

			@include breakpoint(767px down) {
				padding-top: 3rem;
				padding-bottom: 3rem;
			}
		}

		.hs-form .inputs-list {
			input[type="checkbox"] {
				margin-top: 0.2rem;
				margin-right: 0.8rem;
				width: auto !important;
				height: auto !important;
				line-height: 1 !important;
			}

			.hs-form-checkbox {
				width: 50%;
				display: inline-block;
				vertical-align: top;

				> label {
					line-height: 1;
				}
			}
		}

		fieldset.form-columns-1 .input {
			.hs-input {
				width: 100%;
			}
		}

		.form-columns-2 {
			margin-left: -1rem;
			width: calc(100% + 2rem);

			@include breakpoint(480px down) {
				margin-left: 0;
				width: 100%;
			}
		}

		.hs-error-msgs {
			margin-bottom: 0;
			margin-top: 0.5rem;

			li {
				list-style: none;
			}
		}

		.hs-error-msg {
			color: $color-red-1 !important;
			font-family: $font-inter;
			font-size: 1.4rem;
			font-weight: 700;
			line-height: 1.2;
			margin-bottom: 0;
		}

		.hs-form-field {
			margin-bottom: 2.5rem;

			> label {
				font-family: $font-inter;
				font-size: 1.8rem;
				font-weight: 700;
				line-height: 1.2;
				margin-bottom: 1rem;

				span {
					color: inherit;
					font: inherit;
				}
			}

			input,
			select,
			textarea {
				border-radius: 0.3rem;
				font-family: $font-inter;
				font-size: 1.6rem;
				font-weight: 600;
				height: auto;
				line-height: 1.2;
				width: 100% !important;
			}

			select {
				font-size: 1.6rem;

				option {
					color: $color-gray-10;
				}
			}

			.inputs-list {
				margin: 0;
			}

			.hs-form-checkbox {
				list-style: none;

				> label {
					margin: 0;

					> input {
						width: auto !important;
					}

					> span {
						font-family: $font-inter;
						font-size: 1.6rem;
						font-weight: 600;
						line-height: 1.2;
					}
				}
			}
		}

		.hs-button {
			$trans: 0.3s ease 0s;

			background-color: $color-orange-1;
			border: 0.2rem solid $color-orange-1;
			border-radius: 10rem;
			color: $color-white-1;
			cursor: pointer;
			display: inline-block;
			font-family: $font-inter;
			font-size: 1.6rem;
			font-weight: 700;
			line-height: 1.2;
			min-width: 19.5rem;
			padding: 1.6rem 2.1rem;
			text-align: center;
			text-decoration: none;
			transition: background-color $trans border-color $trans color $trans;

			&:hover {
				background-color: $color-green-1;
				border-color: $color-white-1;
				color: $color-white-1;
				text-decoration: none;
			}

			&:focus {
				color: $color-white-1;
			}
		}

		.hs-submit {
			text-align: left;
		}

		.formcraft-css {
			.fc-form {
				background-color: transparent !important;
			}
		}

		h1 {
			font-size: 3.6rem;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			margin-bottom: 3rem;
		}

		p {
			color: $color-gray-10;
			font-size: 2rem;
			line-height: 1.5;
		}

		ol,
		ul {
			margin-bottom: 3rem;

			li {
				color: $color-gray-10;
				font-size: 2rem;
			}
		}

		> * {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__img {
		margin-bottom: 3rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__title {
		@extend %o-title-inter-semibold-40;
		color: $color-green-1;
		line-height: 1.2;
		margin-bottom: 2.5rem;

		&.color-text-white {
			color: $color-white-1;
		}

		&.font-size-36 {
			font-size: 3.6rem;
		}
	}
}
