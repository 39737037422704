.column-content-cc {
	$this: &;

	&__row {
		$gap: 5rem;
		margin-left: -$gap;
		margin-right: -$gap;

		@include breakpoint(1023px down) {
			margin-left: 0;
			margin-right: 0;
		}
	}

	&__col {
		$gap: 5rem;

		padding-left: $gap;
		padding-right: $gap;

		@include breakpoint(1024px up) {
			width: 50%;
		}

		@include breakpoint(1023px down) {
			padding-left: 0;
			padding-right: 0;
			margin-bottom: 3.5rem;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__text {
		margin-bottom: 4.5rem;
		position: relative;

		&.max-width-450 {
			max-width: 45rem;
		}

		h2,
		h3 {
			@extend %o-title-inter-semibold-24;
			margin-bottom: 2rem;
			color: $color-charcoal;
		}

		p {
			color: $color-charcoal;
		}

		.button-box {
			margin-top: 2rem;
		}
	}

	&__uimg {
		&.border-radius-15 {
			img {
				border-radius: 1.5rem;
			}
		}

		&.box-shadow-2-2-25-0 {
			img {
				box-shadow: 0.2rem 0.2rem 2.5rem 0 rgba(0, 0, 0, 0.15);
			}
		}

		&.image-width-70p {
			img {
				@include breakpoint(1024px up) {
					width: 70%;
				}
			}
		}
	}
}
