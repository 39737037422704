// Container Styling
@use "sass:math";

html,
body {
	font-size: 62.5%;
	-webkit-text-size-adjust: none; /* 2 */
	-ms-text-size-adjust: none; /* 2 */
}

body {
	overflow: auto;

	&.hamburger-active,
	&.overflow-hidden {
		overflow: hidden;
	}
}

main {
	// BUGFIX: don't enable overflow hidden
	// will cause how it works section not to stick
	// overflow: hidden;
}

:focus {
	border: 0;
	box-shadow: none;
	outline: 0;
}

.grid-container {
	padding-left: 0;
	padding-right: 0;
	max-width: 120rem;

	@include breakpoint(1080px down) {
		max-width: 90%;
	}

	&--1366 {
		max-width: 136.6rem;
	}
}

// Text Styling

h1 {
	font-family: $font-inter;
	font-size: 5.2rem;
	font-weight: 700;
	line-height: 1.2;
	margin-bottom: 1rem;
	margin-block-start: 0;

	a {
		color: inherit;
		font: inherit;
	}
}

h2 {
	font-family: $font-inter;
	font-size: 4.8rem;
	font-weight: 700;
	line-height: 1.2;
	margin-bottom: 1rem;
	margin-block-start: 0;

	a {
		color: inherit;
		font: inherit;
	}
}

h3 {
	font-family: $font-inter;
	font-size: 3.2rem;
	font-weight: 700;
	line-height: 1.2;
	margin-bottom: 1rem;
	margin-block-start: 0;

	a {
		color: inherit;
		font: inherit;
	}
}

h4 {
	font-family: $font-inter;
	font-size: 2.4rem;
	font-weight: 700;
	line-height: 1.2;
	margin-bottom: 1rem;
	margin-block-start: 0;

	a {
		color: inherit;
		font: inherit;
	}
}

h5 {
	font-family: $font-inter;
	font-size: 1.8rem;
	font-weight: 700;
	line-height: 1.2;
	margin-bottom: 1rem;
	margin-block-start: 0;

	a {
		color: inherit;
		font: inherit;
	}
}

h6 {
	font-family: $font-inter;
	font-size: 1.6rem;
	font-weight: 700;
	line-height: 1.2;
	margin-bottom: 1rem;
	margin-block-start: 0;

	a {
		color: inherit;
		font: inherit;
	}
}

a {
	color: $color-pumpkin-3;
	text-decoration: none;

	@include transition(color 0.4s ease);

	&:focus {
		color: initial;
	}

	&:hover {
		color: $color-midnight-green-2;
		text-decoration: underline;
	}
}

p {
	@extend %o-text-inter-regular-16;
	color: $color-charcoal;
	line-height: 1.6;
	margin-bottom: 1.5rem;

	a {
		color: inherit;
		transition: all 0.3s !important;
		text-decoration: underline;

		&:hover {
			color: $color-orange-1;
		}
	}

	&:last-child {
		margin-bottom: 0;
	}
}

ul,
ol {
	list-style-position: inside;
	padding: 0;

	li {
		@extend %o-text-inter-regular-16;
		line-height: 1.2;
		margin-bottom: 0.5rem;

		&:last-child {
			margin-bottom: 0;
		}
	}
}

i,
em {
	font-variation-settings: "slnt" -10;
}

form {
	position: relative;
}

table {
	background-color: transparent;
	border-spacing: 0;
	border-collapse: collapse;
	color: $color-gray-5;
	font-family: $font-inter;
	font-size: 1.3rem;
	width: 100%;
	margin-bottom: 15px;

	td,
	th {
		border: 1px solid hsla(0, 0%, 50.2%, 0.5019607843137255);
		line-height: 1.5;
		vertical-align: top;
		padding: 15px;
	}

	th {
		font-weight: 700;
	}

	td {
		font-weight: 300;
	}

	tfoot,
	thead {
		th {
			font-size: 1em;
		}
	}

	caption + thead tr:first-child td,
	caption + thead tr:first-child th,
	colgroup + thead tr:first-child td,
	colgroup + thead tr:first-child th,
	thead:first-child tr:first-child td,
	thead:first-child tr:first-child th {
		border-top: 1px solid hsla(0, 0%, 50.2%, 0.5019607843137255);
	}

	tbody {
		> tr {
			&:nth-child(odd) {
				> td,
				> th {
					background-color: hsla(0, 0%, 50.2%, 0.07058823529411765);
				}
			}

			&:hover {
				> td,
				> th {
					background-color: hsla(0, 0%, 50.2%, 0.10196078431372549);
				}
			}
		}

		+ tbody {
			border-top: 2px solid hsla(0, 0%, 50.2%, 0.5019607843137255);
		}
	}

	@include breakpoint(767px down) {
		table {
			font-size: 0.8em;
		}

		table td,
		table th {
			padding: 7px;
			line-height: 1.3;
		}

		table th {
			font-weight: 400;
		}
	}
}

// Visibility Setting

.visuallyhidden {
	position: absolute !important;
	clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
	clip: rect(1px, 1px, 1px, 1px);
	padding: 0 !important;
	border: 0 !important;
	height: 0.1rem !important;
	width: 0.1rem !important;
	overflow: hidden;
}

// Dropanchor

.dropanchor {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 2;

	&--full {
		height: 100%;
		left: 0;
		position: absolute;
		top: 0;
		width: 100%;
		z-index: 5;
	}
}

// No Click

.no-click {
	pointer-events: none;
}

// Image

img {
	-webkit-backface-visibility: hidden; /* Safari */
	backface-visibility: hidden;
	max-width: 100%;
	width: auto;

	&:not([src]):not([srcset]) {
		visibility: hidden;
	}
}

// CHECKLIST DEFAULT
.checklist-d {
	&.checkmark-background-color-pumpkin {
		ul {
			li {
				&::before {
					background: url("../images/checklist-pumpkin.svg") no-repeat scroll 0
						0 transparent;
				}
			}
		}
	}

	ul {
		margin-left: 0;
		margin-bottom: 1.5rem;

		li {
			list-style: none;
			line-height: 1.6;
			padding-left: 3rem;
			margin-bottom: 1.5rem;
			position: relative;

			&::before {
				display: block;
				content: "";
				width: 2.1rem;
				height: 2.1rem;
				margin-top: -1rem;
				position: absolute;
				top: 50%;
				left: 0;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}

// Hubspot

.form-columns-1,
.form-columns-2 {
	max-width: none !important;

	.input {
		margin-right: 0 !important;
	}
}

.form-columns-2 {
	.hs-form-field {
		padding-left: 1rem;
		padding-right: 1rem;

		@include breakpoint(480px down) {
			padding: 0;
		}
	}
}

.hs-form-field {
	label {
		color: $color-gray-3;

		span {
			color: inherit;
			font-family: $font-inter;
			font-size: 1.6rem;
			font-weight: 700;

			&.hs-form-required {
				color: $color-black-2;
			}
		}
	}

	input,
	select,
	textarea {
		background-color: $color-white-1;
		border: 1px solid #666;
		border-radius: 0.5rem;
		padding: 0.9rem 2rem;
		height: 4.36rem;
	}

	input {
		line-height: 4.36rem;
	}

	select {
		background-color: $color-white-1;
		text-indent: 0.001rem;
		text-overflow: "";
		background-image: url("../../assets/images/chevron-down.svg");
		background-repeat: no-repeat;
		background-size: 1.6rem 1rem;
		background-position: right 0 top 50%;
	}

	&.hs-email {
		input {
			width: 100% !important;
		}
	}

	&.hs-primary_commodities,
	&.hs-productos_primarios,
	&.hs-productos {
		.input {
			height: 16rem;
			overflow-y: auto;
		}

		ul {
			margin: 0;

			.hs-form-checkbox {
				@include breakpoint(767px down) {
					width: 50% !important;
				}
			}
		}
	}

	.btn-group {
		width: 100%;

		.multiselect {
			background-color: transparent;
			background-image: url("../../assets/images/chevron-down.svg");
			background-repeat: no-repeat;
			background-size: 1.6rem 1rem;
			background-position: right 1.2em top 50%;
			border-color: $color-white-1;
			border-radius: 0.3rem;
			color: $color-white-1;
			font-weight: 400;
			text-align: left;
			width: 100%;

			&:hover {
				background-color: transparent;
			}

			.caret {
				display: none;
			}
		}

		.multiselect-container {
			height: 22rem;
			overflow-y: auto;
			width: 100%;

			> li {
				line-height: 1;
				margin-bottom: 0;

				> a {
					text-decoration: none;

					> label {
						color: $color-gray-3 !important;

						> input[type="checkbox"] {
							margin-bottom: 0;
							margin-top: 0.2rem;
						}
					}

					&:hover {
						background-color: $color-blue-1;

						> label {
							color: $color-white-1 !important;
						}
					}
				}

				&.active {
					> a {
						> label {
							color: $color-white-1 !important;
						}
					}
				}
			}
		}
	}
}

.hs-form-checkbox {
	> label {
		display: flex;
		flex-flow: row wrap;
		align-items: flex-start;

		> input {
			flex: 0 0 auto;
			margin-top: 0.2rem;
		}

		> span {
			flex: 1 1 0;
			font-weight: 400;
		}
	}
}

.hs-submit {
	text-align: center;
}

.wpml-ls-statics-footer {
	display: none;
}

.otgs-development-site-front-end {
	display: none !important;
}

// FORMCRAFT3
html body {
	.formcraft-css {
		.fc-form {
			box-shadow: none;
			border-radius: 0;

			> p {
				// BUGFIX: if form tooltip appears
				&:nth-last-child(2) {
					margin-bottom: 0;
				}
			}

			.form-page-content {
				padding: 0 !important;
			}

			.label-block {
				.field-cover {
					> div {
						margin-top: 0;
					}
				}
			}

			.customText-cover {
				p {
					color: $color-gray-3 !important;
					font-size: 1.8rem !important;
				}

				strong {
					color: inherit !important;
					font: inherit !important;
					font-weight: 700 !important;
				}
			}

			.field-cover {
				// LABEL
				> span {
					padding-right: 0;
					margin-bottom: 1rem;

					> span {
						line-height: 1;

						> span {
							color: $color-gray-3;
							font-family: $font-inter;
							font-size: 1.8rem;
							font-weight: 700;
							line-height: 1.2;
						}

						&.sub-label {
							margin-top: 0.5rem;
							opacity: 1;

							b,
							span {
								color: $color-gray-3;
								font-family: $font-inter;
								font-size: 1.2rem;
								line-height: 1.4;
							}

							> span {
								font-weight: 400;
							}
						}
					}
				}

				> p {
					display: none;
				}
			}

			.form-element {
				margin-bottom: 2.5rem;

				// is required (*)
				&.is-required-true .field-cover .main-label > span::after {
					color: $color-gray-3 !important;
					top: 0;
					right: auto;
				}

				.form-element-html {
					padding-bottom: 0;

					input[type="color"],
					input[type="date"],
					input[type="datetime-local"],
					input[type="datetime"],
					input[type="email"],
					input[type="month"],
					input[type="number"],
					input[type="password"],
					input[type="search"],
					input[type="tel"],
					input[type="text"],
					input[type="time"],
					input[type="url"],
					input[type="week"],
					select,
					textarea {
						background-color: $color-white-1;
						border: 2px solid #666;
						border-radius: 0.3rem;
						color: $color-gray-3;
						font-family: $font-inter;
						font-size: 1.6rem;
						font-weight: 600;
						height: auto;
						line-height: 1.2;
						width: 100% !important;

						&:focus {
							outline: none;
							box-shadow: 0 0 5px #cacaca;
							transition:
								box-shadow 0.5s,
								border-color 0.25s ease-in-out;
						}
					}
				}

				// FILE UPLOAD
				.fileupload-cover.field-cover {
					.button-file {
						span {
							color: $color-gray-3;
							font-family: $font-inter;
							font-size: 1.2rem;
						}
					}
				}

				// CHECKBOX
				.checkbox-cover > div label {
					color: $color-gray-3;
					font-family: $font-inter;
					font-size: 1.6rem;
				}

				// SUBMIT
				.submit-cover {
					.submit-button {
						$trans: 0.3s ease 0s;

						background-color: $color-orange-1 !important;
						border: 0.2rem solid $color-orange-1;
						border-radius: 10rem !important;
						color: $color-white-1 !important;
						cursor: pointer;
						display: inline-block;
						font-family: $font-inter;
						font-size: 1.6rem;
						font-weight: 700;
						line-height: 1.2;
						min-width: 19.5rem;
						padding: 1.6rem 2.1rem;
						text-align: center;
						text-decoration: none;
						transition: background-color $trans border-color $trans color $trans;

						span {
							color: inherit !important;
							font: inherit;
						}

						&:hover {
							background-color: $color-green-1 !important;
							border-color: $color-white-1;
							box-shadow: none;
							color: $color-white-1 !important;
							text-decoration: none;

							span {
								color: inherit !important;
							}
						}

						&:focus {
							color: $color-white-1;

							span {
								color: inherit !important;
							}
						}
					}
				}

				// ERROR
				.error {
					background-color: transparent;
					color: $color-red-1;
					font-family: $font-inter;
					font-size: 1.4rem;
					font-weight: 700;
					line-height: 1.2;
					margin-top: 0.5rem;
					top: 100%;
					bottom: auto;
					right: 0;
				}
			}
		}
	}
}
