.photo-content-b {
	$this: &;

	&.layout-plain {
		&.content-left {
			#{$this}__media {
				padding-left: 3rem;

				@include breakpoint(1023px down) {
					padding-left: 0;
				}
			}

			#{$this}__content {
				padding-right: 6.5rem;

				@include breakpoint(1023px down) {
					padding-right: 0;
				}
			}
		}

		&.content-right {
			#{$this}__media {
				padding-right: 3rem;

				@include breakpoint(1023px down) {
					padding-right: 0;
				}
			}

			#{$this}__content {
				padding-left: 6.5rem;

				@include breakpoint(1023px down) {
					padding-left: 0;
				}
			}
		}

		#{$this}__wrapper {
			padding-left: 9rem;
			padding-right: 9rem;
			position: relative;

			@include breakpoint(1023px down) {
				border-radius: 2.5rem;
				background-color: $color-anti-flash-white-2;
				padding: 5rem;
			}

			@include breakpoint(767px down) {
				padding: 3rem;
			}
		}

		#{$this}__content {
			padding-top: 7rem;
			padding-bottom: 7rem;

			@include breakpoint(1023px down) {
				padding-top: 5rem;
				padding-bottom: 0;
			}

			@include breakpoint(767px down) {
				padding-top: 3rem;
			}
		}
	}

	&.layout-circular {
		&.content-left {
			#{$this}__content {
				padding-right: 5.5rem;

				@include breakpoint(1023px down) {
					padding-right: 0;
				}
			}
		}

		&.content-right {
			#{$this}__content {
				padding-left: 5.5rem;

				@include breakpoint(1023px down) {
					padding-left: 0;
				}
			}
		}

		#{$this}__wrapper {
			padding-left: 3rem;
			padding-right: 10rem;
			position: relative;

			@include breakpoint(1023px down) {
				padding: 5rem;
			}

			@include breakpoint(767px down) {
				padding: 3rem;
			}
		}

		#{$this}__bg {
			box-shadow: 0 0 1.5rem rgba(0, 0, 0, 0.1);

			> div {
				&:nth-child(1) {
					background-color: $color-white-1;
					border-radius: 95% 0 0 0;
					width: 51%;
					height: 130%;
					position: absolute;
					top: -30%;
					left: 0;

					@include breakpoint(1023px down) {
						border-radius: 60% 0 0 0;
						width: 100%;
						height: 100%;
						top: 0;
					}

					@include breakpoint(767px down) {
						border-radius: 40% 0 0 0;
					}
				}

				&:nth-child(2) {
					background-color: $color-white-1;
					width: 50%;
					height: 100%;
					position: absolute;
					top: 0;
					right: 0;

					@include breakpoint(1023px down) {
						display: none;
					}
				}
			}
		}

		#{$this}__content {
			padding-top: 7rem;
			padding-bottom: 7rem;

			@include breakpoint(1023px down) {
				padding-top: 5rem;
				padding-bottom: 0;
			}

			@include breakpoint(767px down) {
				padding-top: 3rem;
			}
		}
	}

	&.content-width-60p {
		#{$this}__content {
			width: 60%;

			@include breakpoint(1279px down) {
				width: 50%;
			}

			@include breakpoint(1023px down) {
				width: 100%;
			}
		}
	}

	&.media-width-40p {
		#{$this}__media {
			width: 40%;

			@include breakpoint(1279px down) {
				width: 50%;
			}

			@include breakpoint(1023px down) {
				width: 100%;
			}
		}
	}

	&__bg {
		border-radius: 2.5rem;
		background-color: $color-anti-flash-white-2;
		width: 100%;
		overflow: hidden;
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 1;

		@include breakpoint(1023px down) {
			height: 100% !important;
		}
	}

	&__media {
		position: relative;
		z-index: 2;
	}

	&__img {
		position: relative;

		&:hover {
			.btn-play {
				background-color: $color-midnight-green;
			}
		}

		img {
			width: 100%;
		}

		> a {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
		}

		.btn-play {
			pointer-events: none;
			position: absolute;
			top: 50%;
			left: 50%;
			z-index: 2;
			transform: translate(-50%, -50%);
		}
	}

	&__content {
		position: relative;
		z-index: 2;
	}

	&__title {
		@extend %o-title-inter-semibold-40;
		color: $color-charcoal;
		line-height: 1.2;
		margin-bottom: 2rem;

		&.color-text-midnight-green {
			color: $color-midnight-green;
		}
	}

	&__text {
		position: relative;

		&.color-text-midnight-green {
			li,
			p {
				color: $color-midnight-green;
			}
		}

		li,
		p {
			color: $color-charcoal;
			font-size: 2rem;
		}

		.button-box {
			margin-top: 2rem;

			&:first-child {
				margin-top: 0;
			}

			> a {
				margin-right: 1.5rem;

				@include breakpoint(1024px down) {
					margin-bottom: 1.5rem;
					margin-right: 0;
					text-align: center;
				}

				@include breakpoint(1023px down) {
					display: inline-block;
					margin-right: 1.5rem;
					margin-bottom: 0;
				}

				@include breakpoint(767px down) {
					margin-bottom: 1.5rem;
					margin-right: 0;
				}

				&.btn-link {
					margin-left: 3rem;

					@include breakpoint(1024px down) {
						margin-left: 0;
					}

					@include breakpoint(1023px down) {
						margin-left: 3rem;
					}

					@include breakpoint(767px down) {
						margin-left: 3rem;
					}
				}

				&:last-child {
					margin-right: 0;

					@include breakpoint(1024px down) {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}
