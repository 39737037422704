.column-content-d {
	$this: &;

	&.col-4 {
		#{$this}__col {
			width: 25%;

			@include breakpoint(1024px down) {
				margin-bottom: 2.4rem;
				width: 50%;
			}

			@include breakpoint(767px down) {
				padding: 0;
				width: 100%;

				&:last-child {
					margin-bottom: 0;
				}
			}

			#{$this}__col-img {
				height: 19rem;

				@include breakpoint(1024px down) {
					height: 28rem;
				}

				@include breakpoint(1023px down) {
					height: 23.5rem;
				}
			}

			#{$this}__col-title {
				font-size: 1.8rem;

				@include breakpoint(1023px down) {
					font-size: 2.4rem;
				}
			}

			#{$this}__col-content {
				height: calc(100% - 19rem);
				padding: 3.5rem 2rem;

				@include breakpoint(1024px down) {
					height: calc(100% - 28rem);
				}

				@include breakpoint(1023px down) {
					height: calc(100% - 23.5rem);
				}
			}
		}

		#{$this}__link {
			@include breakpoint(1024px down) {
				margin-top: 2.6rem;
			}
		}
	}

	&.list {
		#{$this}__col {
			margin-bottom: 6rem;

			@include breakpoint(767px down) {
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	&.is-video {
		#{$this}__col-img {
			&:before {
				background-color: rgba(21, 67, 68, 0.75);
				content: "";
				height: 100%;
				left: 0;
				position: absolute;
				top: 0;
				width: 100%;
			}
		}
	}

	&__row {
		display: flex;
		flex-flow: row wrap;
		margin-left: -1.25rem;
		width: calc(100% + 2.5rem);

		@include breakpoint(767px down) {
			margin-left: 0;
			width: 100%;
		}
	}

	&__title {
		@extend %o-title-inter-semibold-28;
		flex: 1 1 0;
		color: $color-gray-4;
		line-height: 1.2;
		margin-bottom: 5rem;
		margin-top: 0;
		text-align: center;
	}

	&__col {
		flex: 0 1 auto;
		padding: 0 1.25rem;
		width: 33.33%;

		&.d-block {
			display: block;
		}

		&.full {
			width: 100% !important;
		}

		@include breakpoint(1023px down) {
			margin-bottom: 2.4rem;
			width: 50%;
		}

		@include breakpoint(767px down) {
			padding: 0;
			width: 100%;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__col-box {
		box-shadow: 0.2rem 0.2rem 1.5rem #e4e8ef;
		border-radius: 0.8rem;
		height: 100%;
		overflow: hidden;
		position: relative;
		width: 100%;
	}

	&__col-img {
		@extend %background-image;
		height: 23.5rem;
		position: relative;
		width: 100%;
	}

	&__col-play {
		border: 0.1rem solid $color-white-1;
		border-radius: 10rem;
		height: 10rem;
		left: 50%;
		position: absolute;
		top: 50%;
		width: 10rem;
		transform: translate(-50%, -50%);

		&:before {
			border-top: 2.3rem solid transparent;
			border-bottom: 2.3rem solid transparent;
			border-left: 3.5rem solid $color-white-1;
			content: "";
			height: 0;
			left: 50%;
			margin-left: 0.5rem;
			position: absolute;
			top: 50%;
			width: 0;
			transform: translate(-50%, -50%);
		}
	}

	&__col-content {
		background-color: $color-white-1;
		height: calc(100% - 23.5rem);
		padding: 4rem 3rem;
	}

	&__col-cat {
		@extend %o-text-inter-regular-16;
		color: $color-gray-5;
		line-height: 1.2;
		margin-bottom: 1rem;

		span {
			color: inherit;
			font: inherit;
			text-transform: capitalize;
		}
	}

	&__col-logo {
		margin-bottom: 1rem;

		img {
			height: 2rem;
		}
	}

	&__col-title {
		@extend %o-title-inter-bold-24;
		color: $color-gray-5;
		line-height: 1.2;
		margin-bottom: 0;
		margin-top: 0;
	}

	&__link {
		margin-top: 5rem;
		text-align: center;

		@include breakpoint(1023px down) {
			margin-top: 2.6rem;
		}
	}

	&__loader {
		display: none;
		text-align: center;
	}

	&__col-message {
		@extend %o-title-inter-bold-18;
		color: $color-gray-5;
		line-height: 1.2;
		margin-bottom: 0;
		text-align: center;
	}

	&__pagination {
		display: flex;
		flex-flow: row wrap;
		justify-content: center;
		padding: 0 12.5rem;
		text-align: center;
		width: 100%;

		.page-numbers {
			border: 0.1rem solid $color-gray-3;
			border-radius: 3rem;
			color: $color-black-3;
			display: block;
			font-family: "Inter";
			font-weight: 400;
			font-size: 1.1rem;
			height: 3rem;
			line-height: 3rem;
			margin: 0 0.5rem;
			width: 3rem;

			&:hover {
				text-decoration: none;
			}

			&.current {
				background-color: $color-orange-1;
				border-color: $color-orange-1;
				color: $color-white-1;
				font-weight: 700;
			}

			&.prev,
			&.next {
				border: 0;
				margin: 0;
			}
		}
	}
}
