@mixin pcd_contentMaxWidth_layoutDefault($amp, $this, $max_width) {
	@include breakpoint(768px up) {
		#{$this}__content {
			max-width: #{$max_width}rem;
		}
	}

	@at-root #{$amp}.col-gap-50 {
		#{$this}__content {
			@include breakpoint(768px up) {
				max-width: #{$max_width + 3}rem;
			}

			@include breakpoint(1024px up) {
				max-width: #{$max_width + 5}rem;
			}
		}
	}

	@at-root #{$amp}.col-gap-90 {
		#{$this}__content {
			@include breakpoint(768px up) {
				max-width: #{$max_width + 3}rem;
			}

			@include breakpoint(1024px up) {
				max-width: #{$max_width + 6}rem;
			}

			@include breakpoint(1199px up) {
				max-width: #{$max_width + 9}rem;
			}
		}
	}

	@at-root #{$amp}.col-gap-150 {
		#{$this}__content {
			@include breakpoint(768px up) {
				max-width: #{$max_width + 3}rem;
			}

			@include breakpoint(1024px up) {
				max-width: #{$max_width + 6}rem;
			}

			@include breakpoint(1199px up) {
				max-width: #{$max_width + 15}rem;
			}
		}
	}
}

.photo-content-d {
	$this: &;

	&.col-gap-50 {
		&.content-left {
			#{$this}__content {
				@include breakpoint(768px up) {
					padding-right: 3rem;
				}

				@include breakpoint(1024px up) {
					padding-right: 5rem;
				}

				@include breakpoint(767px down) {
					padding-right: 0;
				}
			}
		}

		&.content-right {
			#{$this}__content {
				@include breakpoint(768px up) {
					padding-left: 3rem;
				}

				@include breakpoint(1024px up) {
					padding-left: 5rem;
				}

				@include breakpoint(767px down) {
					padding-left: 0;
				}
			}
		}
	}

	&.col-gap-90 {
		&.content-left {
			#{$this}__content {
				@include breakpoint(768px up) {
					padding-right: 3rem;
				}

				@include breakpoint(1024px up) {
					padding-right: 6rem;
				}
	
				@include breakpoint(1199px up) {
					padding-right: 9rem;
				}

				@include breakpoint(767px down) {
					padding-right: 0;
				}
			}
		}

		&.content-right {
			#{$this}__content {
				@include breakpoint(768px up) {
					padding-left: 3rem;
				}

				@include breakpoint(1024px up) {
					padding-left: 6rem;
				}
	
				@include breakpoint(1199px up) {
					padding-left: 9rem;
				}

				@include breakpoint(767px down) {
					padding-left: 0;
				}
			}
		}
	}

	&.col-gap-150 {
		&.content-left {
			#{$this}__content {
				@include breakpoint(768px up) {
					padding-right: 3rem;
				}

				@include breakpoint(1024px up) {
					padding-right: 6rem;
				}
	
				@include breakpoint(1199px up) {
					padding-right: 15rem;
				}

				@include breakpoint(767px down) {
					padding-right: 0;
				}
			}
		}

		&.content-right {
			#{$this}__content {
				padding-right: 5rem;

				@include breakpoint(768px up) {
					padding-left: 3rem;
				}
	
				@include breakpoint(1024px up) {
					padding-left: 6rem;
				}
	
				@include breakpoint(1199px up) {
					padding-left: 15rem;
				}

				@include breakpoint(767px down) {
					padding-left: 0;
				}
			}
		}
	}

	&.content-left {
		#{$this}__media {
			order: 2;
			justify-content: right;
			text-align: right;
		}

		#{$this}__content {
			order: 1;
		}
	}

	&.content-right {
		#{$this}__content {
			padding-left: 6rem;
			padding-right: 6rem;

			@include breakpoint(767px down) {
				padding-left: 0;
				padding-right: 0;
			}
		}
	}

	&.content-width-50p {
		#{$this}__content {
			width: 50%;

			@include breakpoint(767px down) {
				width: 100%;
			}
		}
	}

	&.content-max-width-300 {
		&.layout-default {
			@include pcd_contentMaxWidth_layoutDefault(&, $this, 30);
		}
	}

	&.content-max-width-370 {
		&.layout-default {
			@include pcd_contentMaxWidth_layoutDefault(&, $this, 37);
		}
	}

	&.content-max-width-420 {
		&.layout-default {
			@include pcd_contentMaxWidth_layoutDefault(&, $this, 42);
		}
	}

	&.content-max-width-520 {
		&.layout-default {
			@include pcd_contentMaxWidth_layoutDefault(&, $this, 52);
		}
	}

	&.content-max-width-640 {
		&.layout-default {
			@include pcd_contentMaxWidth_layoutDefault(&, $this, 64);
		}
	}

	&.content-max-width-62p {
		&.layout-default {
			&.col-gap-90 {
				#{$this}__content {
					@include breakpoint(768px up) {
						max-width: #{30 + 3}rem;
					}
	
					@include breakpoint(1024px up) {
						max-width: #{40 + 6}rem;
					}
	
					@include breakpoint(1199px up) {
						max-width: calc(62% + 9rem);
					}
				}
			}
	
			&.col-gap-150 {
				#{$this}__content {
					@include breakpoint(768px up) {
						max-width: #{30 + 3}rem;
					}
	
					@include breakpoint(1024px up) {
						max-width: #{40 + 6}rem;
					}
	
					@include breakpoint(1199px up) {
						max-width: calc(62% + 15rem);
					}
				}
			}
		}
	}

	&.media-half-bleed {
		&.no-animation {
			&.content-left {
				@include breakpoint(1280px up) {
					#{$this}__media {
						right: 0;
					}
				}
			}
		}

		@include breakpoint(1280px up) {
			position: relative;

			#{$this}__media {
				position: absolute;
				top: 50%;
				transform: translateY(-50%);
			}
		}
	}

	&.media-padding-left-150 {
		#{$this}__media {
			@include breakpoint(1024px up) {
				padding-left: 15rem;
			}
		}
	}

	&.media-width-50p {
		#{$this}__media {
			width: 50%;

			@include breakpoint(767px down) {
				width: 100%;
			}
		}
	}

	&.media-width-auto {
		#{$this}__media {
			@include breakpoint(768px up) {
				@include auto();
			}
		}
	}

	&__media {
		@include breakpoint(767px down) {
			margin-bottom: 3rem;
		}

		> * {
			margin-bottom: 5rem;

			@include breakpoint(1279px down) {
				margin-bottom: 2.5rem;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__bgi {
		@extend %background-image;
		min-height: 55rem;
		position: relative;
		width: 100%;

		@include breakpoint(1023px down) {
			min-height: 45rem;
		}

		@include breakpoint(767px down) {
			min-height: 35rem;
			margin-bottom: 2rem;
		}
	}

	&__img {
		@include breakpoint(767px down) {
			order: 2;
		}

		img {
			width: 100% !important;
		}
	}

	&__mil {
		@include breakpoint(768px up) {
			padding-left: 1.5rem;
		}

		@include breakpoint(767px down) {
			order: 1;
			padding-left: 1rem;
			margin-bottom: 2rem;

			&:first-child {
				margin-bottom: 0;
			}

			&:last-child {
				margin-bottom: 2rem;
			}
		}

		&.col-2 {
			@include breakpoint(1280px up) {
				#{$this}__mil-col {
					width: 50%;
				}
			}

			@media screen and (max-width: 1279px) and (min-width: 768px) {
				#{$this}__mil-col {
					margin-bottom: 2rem;
				}
			}

			@include breakpoint(767px down) {
				#{$this}__mil-col {
					width: 50%;
				}
			}
		}
	}

	&__mil-row {
		$gap: 1.7rem;
		margin-left: -$gap;
		margin-right: -$gap;
	}

	&__mil-col {
		$gap: 1.7rem;

		padding-left: $gap;
		padding-right: $gap;
	}

	&__content {
		@include breakpoint(767px down) {
			margin-bottom: 2rem;
		}

		> * {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__title {
		@extend %o-title-inter-semibold-40;
		color: $color-charcoal;
		line-height: 1.2;
		margin-bottom: 2rem;
		position: relative;

		&.color-text-midnight-green {
			color: $color-midnight-green;
		}

		&.font-size-30 {
			font-size: 3rem;
		}

		&.font-size-50 {
			font-size: 5rem;
		}

		&.max-width-350 {
			max-width: 35rem;
		}

		&.max-width-450 {
			max-width: 45rem;
		}

		&.max-width-500 {
			max-width: 50rem;
		}
	}

	&__border {
		background-color: $color-yellow-1;
		width: 10.5rem;
		height: 0.3rem;
		margin-top: 2rem;
		margin-bottom: 2rem;
	}

	&__text {
		position: relative;
		margin-bottom: 4rem;

		&:hover {
			#{$this}__tooltip {
				opacity: 1;
				visibility: visible;
			}
		}

		&.color-text-midnight-green {
			li,
			p {
				color: $color-midnight-green;
			}
		}

		&.font-size-16 {
			li,
			p {
				font-size: 1.6rem;
			}
		}

		&.font-size-18 {
			li,
			p {
				font-size: 1.8rem;
			}
		}

		&.font-size-22 {
			li,
			p {
				font-size: 2.2rem;
			}
		}

		&.font-size-24 {
			li,
			p {
				font-size: 2.4rem;
			}
		}

		&.margin-bottom-20 {
			margin-bottom: 2rem;
		}

		&.max-width-300 {
			max-width: 30rem;
		}

		&.max-width-450 {
			max-width: 45rem;
		}

		li,
		p {
			color: $color-charcoal;
			font-size: 2rem;
		}

		.button-box {
			margin-top: 2rem;

			&:first-child {
				margin-top: 0;
			}

			> a {
				margin-right: 1.5rem;

				@include breakpoint(1024px down) {
					margin-bottom: 1.5rem;
					margin-right: 0;
					text-align: center;
				}

				@include breakpoint(1023px down) {
					display: inline-block;
					margin-right: 1.5rem;
					margin-bottom: 0;
				}

				@include breakpoint(767px down) {
					margin-bottom: 1.5rem;
					margin-right: 0;
				}

				&.btn-link {
					margin-left: 3rem;

					@include breakpoint(1024px down) {
						margin-left: 0;
					}

					@include breakpoint(1023px down) {
						margin-left: 3rem;
					}

					@include breakpoint(767px down) {
						margin-left: 3rem;
					}
				}

				&:last-child {
					margin-right: 0;

					@include breakpoint(1024px down) {
						margin-bottom: 0;
					}
				}
			}
		}
	}

	&__aster {
		@include breakpoint(768px up) {
			position: relative;
		}
	}

	&__tooltip {
		$trans: 0.4s ease 0s;

		border-radius: 0.5rem;
		line-height: 0;
		width: 20rem;
		padding: 1rem;
		opacity: 0;
		visibility: hidden;
		position: absolute;
		transition:
			opacity $trans,
			visibility $trans;

		&.color-text-white {
			#{$this}__tooltip-text {
				color: $color-white-1 !important;
			}
		}

		&.position-right {
			@include breakpoint(768px up) {
				margin-top: -0.5rem;
				margin-left: 1rem;
				top: 50%;
				left: 100%;
				transform: translateY(-50%);

				#{$this}__tooltip-arrow {
					border-top: 0.6rem solid transparent;
					border-bottom: 0.6rem solid transparent;
					border-right: 0.6rem solid transparent;
					margin-left: 1rem;
					top: 50%;
					right: 100%;
					transform: translateY(-50%);
				}
			}

			@include breakpoint(767px down) {
				margin-bottom: 1rem;
				bottom: 100%;
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}

	&__tooltip-text {
		color: $color-charcoal !important;
		font-size: 1rem !important;
		line-height: 1;

		&:last-of-type {
			margin-bottom: 0;
		}
	}

	&__tooltip-arrow {
		position: absolute;

		@include breakpoint(767px down) {
			border-left: 0.6rem solid transparent;
			border-right: 0.6rem solid transparent;
			border-top: 0.6rem solid transparent;
			top: 100%;
			left: 50%;
			transform: translateX(-50%);
		}
	}

	&__ic {
		margin-bottom: 4rem;
	}

	&__ic-icon {
		margin-bottom: 1.5rem;
	}

	&__ic-title {
		@extend %o-title-inter-semibold-18;
		color: $color-charcoal;
		line-height: 1.2;
		margin-bottom: 1.5rem;
	}
}
