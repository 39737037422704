.column-content-b {
	$this: &;

	&__cell {
		max-width: 95rem;
	}

	&__row {
		display: flex;
		flex-flow: row wrap;
		margin-left: -1.5rem;
		width: calc(100% + 3rem);

		@include breakpoint(1023px down) {
			justify-content: center;
		}
	}

	&__col {
		flex: 0 1 auto;
		padding: 0 1.5rem;
		width: 33.33%;

		@include breakpoint(1023px down) {
			margin-bottom: 3rem;
			width: 50%;
		}

		@include breakpoint(767px down) {
			width: 100%;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__col-box {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: center;
		border-radius: 0.84rem;
		box-shadow: 0.17rem 0.17rem 1.3rem 0 rgba(0, 0, 0, 0.25);
		height: 100%;
		padding: 3rem;
	}

	&__col-box-inner {
		flex: 0 1 auto;
	}

	&__col-img {
		margin-bottom: 1.5rem;
		padding-bottom: 1.5rem;
		position: relative;
		text-align: center;

		&:before {
			background-color: $color-yellow-1;
			bottom: 0;
			content: "";
			height: 0.17rem;
			left: 50%;
			position: absolute;
			width: 12.5rem;
			transform: translateX(-50%);
		}

		img {
			max-height: 4.5rem;
		}
	}

	&__col-text {
		padding: 0 1rem;
		text-align: center;

		p {
			color: $color-gray-3;
			font-size: 1.35rem;
			font-weight: 700;
			line-height: 1.3;
		}
	}
}
