.column-content-icb3 {
	$this: &;

	&__row {
		$gap: 1.6rem;
		margin-left: -$gap;
		margin-right: -$gap;

		@include breakpoint(1023px down) {
			margin-left: 0;
			margin-right: 0;
		}
	}

	&__col {
		$gap: 1.6rem;
		padding-left: $gap;
		padding-right: $gap;

		@include breakpoint(1023px down) {
			padding-left: 0;
			padding-right: 0;
		}

		&.col-34p {
			width: 34%;

			@include breakpoint(1024px up) {
				.o-cc-icb3-d {
					height: 100%;
				}

				.o-cc-icb3-d__content {
					padding: 3.5rem;
				}
			}
		}

		&.col-32p {
			width: 32%;
		}
	}
}
