%bgd {
	// add z-index to create layers
	@for $i from 1 through 9 {
		&:nth-child(#{10-$i}) {
			z-index: $i;
		}
	}
}

@keyframes pulse-animation {
	0% {
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.2);
	}
	100% {
		box-shadow: 0 0 0 2rem rgba(0, 0, 0, 0);
	}
}

.background-d {
	$this: &;

	position: relative;

	> section {
		position: relative;
		z-index: 10;
	}

	&__circle {
		box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 1);
		border-radius: 50%;
		height: 2rem;
		position: absolute;
		transform: translate(50%, 50%);
		width: 2rem;

		@include breakpoint(1279px down) {
			display: none;
		}

		&--pulse-1 {
			animation: pulse-animation 2s ease infinite;
		}

		&--pulse-2 {
			animation: pulse-animation 2s ease 1s infinite;
		}

		&--pulse-3 {
			animation: pulse-animation 2s ease 1.5s infinite;
		}

		&--orange {
			background: $color-orange-1;
		}

		&--usa {
			left: 29%;
			top: 37%;
		}

		&--south-america {
			left: 36.5%;
			top: 68%;
		}

		&--asia {
			left: 67%;
			top: 43%;
		}
	}

	&__default {
		overflow: hidden;
		@extend %background-image;
		@extend %fill-container;
		@extend %bgd;
	}
}
