// COLUMN CONTENT - FEATURED - RESOURCES PAGE POST
.o-cc-f-rpp {
	background-color: $color-floral-white;
	border-radius: 1.5rem;
	box-shadow: 0.2rem 0.2rem 1.5rem 0 rgba(0, 0, 0, 0.25);
	margin-bottom: 2.4rem;
	overflow: hidden;
	padding: 4.5rem 5rem;
	position: relative;

	@include breakpoint(767px down) {
		padding: 3rem;
	}

	&:last-child {
		margin-bottom: 0;
	}

	.btn-link {
		border-color: $color-midnight-green;
		color: $color-midnight-green;

		&:hover {
			border-bottom-color: $color-midnight-green;
			color: $color-midnight-green;
		}
	}

	&__bg {
		@extend %background-image;
		background-color: #133535;
		width: 30%;
		height: 90%;
		overflow: hidden;
		position: absolute;
		bottom: 0;
		right: 0;
		z-index: 1;

		&::before {
			display: block;
			border-radius: 100%;
			border: 50rem solid transparent;
			border-color: $color-floral-white;
			content: "";
			width: 200rem;
			height: 200rem;
			pointer-events: none;
			position: absolute;
			top: -59rem;
			left: -53rem;
			z-index: 1;
		}

		@include breakpoint(1023px down) {
			display: none;
		}

		img {
			display: block;
			position: relative;
		}
	}

	&__content {
		position: relative;
		z-index: 2;
	}

	&__cat {
		@extend %o-text-inter-regular-16;
		color: $color-midnight-green;
		line-height: 1.2;
		margin-bottom: 1rem;
	}

	&__title {
		@extend %o-title-inter-bold-24;
		color: $color-midnight-green;
		line-height: 1.2;
		margin-bottom: 2.5rem;

		@include breakpoint(1024px up) {
			max-width: 85%;
		}

		@include breakpoint(767px down) {
			font-size: 3rem;
		}
	}
}
