.map-d {
	$this: &;
	background-color: $color-green-5;
	padding: 1.5rem 0;

	@include breakpoint(1023px down) {
		padding: 5rem 0 1.5rem;
	}

	&__cell {
		max-width: 105rem;
	}

	&__col {
		width: 25%;
		margin-top: 7.7rem;

		@include breakpoint(1023px down) {
			width: 50%;
			margin-bottom: 5rem;
			margin-top: 0;
		}

		@include breakpoint(767px down) {
			width: 100%;
		}
	}

	&__map {
		width: 50%;

		@include breakpoint(1023px down) {
			display: none;
		}
	}

	&__col-item {
		text-align: center;
		width: 100%;

		@include breakpoint(1023px down) {
			margin-bottom: 4rem;
			padding: 0 1.5rem;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__col-item-title {
		background: rgb(255, 255, 255);
		background: -moz-linear-gradient(
			143deg,
			rgba(255, 255, 255, 1) 0%,
			rgba(219, 226, 232, 1) 100%
		);
		background: -webkit-linear-gradient(
			143deg,
			rgba(255, 255, 255, 1) 0%,
			rgba(219, 226, 232, 1) 100%
		);
		background: linear-gradient(
			143deg,
			rgba(255, 255, 255, 1) 0%,
			rgba(219, 226, 232, 1) 100%
		);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#dbe2e8",GradientType=1);
		border-radius: 0.5rem;
		color: $color-gray-8;
		font-family: $font-inter;
		font-size: 2.2rem;
		font-weight: 600;
		line-height: 1.2;
		margin-bottom: 1rem;
		padding: 0.5rem 2rem;
		position: relative;

		span {
			color: inherit;
			font-family: inherit;
			font-weight: 400;
		}

		svg {
			position: absolute;
			left: 50%;
			top: -2rem;
			transform: translateX(-50%);
		}
	}

	&__col-item-text {
		font-size: 2.2rem;
		color: $color-white-1;
		font-family: $font-inter;
		font-size: 2.2rem;
		font-weight: 400;
		line-height: 1.4;

		@include breakpoint(768px up) {
			min-height: 6.2rem;
		}

		p {
			color: inherit;
			font: inherit;
			margin-bottom: 0;
		}
	}

	&__countries {
		width: 100%;
		margin-bottom: 0;
		margin-top: 1rem;

		ul {
			list-style: none;
			margin-left: 0;
			margin-bottom: 0;

			li {
				color: $color-white-1;
				font-family: $font-inter;
				font-size: 1.6rem;
				font-weight: 400;
				margin-bottom: 1.5rem;
				margin-left: 0.5rem;
				padding-left: 1rem;
				position: relative;

				&#{$this}__countries-br {
					margin: 0;
					padding: 0;
					width: 100%;

					&:before {
						content: "";
					}

					@include breakpoint(1023px down) {
						display: none;
					}
				}

				&#{$this}__countries-first,
				&:first-child {
					margin: 0;
					padding: 0;

					&:before {
						content: "";
					}

					@include breakpoint(1023px down) {
						margin-bottom: 1.5rem;
						margin-left: 0.5rem;
						padding-left: 1rem;
						width: auto;
					}
				}

				&#{$this}__countries-first {
					@include breakpoint(1023px down) {
						&:before {
							content: "|";
							position: absolute;
							left: 0;
						}
					}
				}

				&:before {
					content: "|";
					position: absolute;
					left: 0;
				}
			}
		}
	}
}
