.o-cc-ca-ic2 {
	$this: &;
	$trans: 0.4s ease 0s;

	&__it {
		margin-bottom: 1rem;
	}

	&__it-icon {
		width: 3.2rem;
	}

	&__it-title {
		@extend %o-title-inter-semibold-16;
		padding-left: 2rem;
	}

	&__border {
		background-color: #ededed;
		height: 0.2rem;
		margin-top: 1rem;
		margin-bottom: 1rem;
	}

	&__text {
		p {
			line-height: 1.5;
		}
	}
}
