.error-p {
	background-color: $color-green-5;

	&__content {
		padding: 16rem 0;
	}

	&__link {
		.btn {
			@include breakpoint(767px down) {
				min-width: 0;
			}
		}
	}
	
	&__title {
		@extend %o-title-inter-semibold-55;
		color: $color-floral-white;
		margin-bottom: 3.3rem;
		margin-top: 1rem;

		@include breakpoint(767px down) {
			font-weight: 600;
			font-size: 2.7rem;
		}
	}
}