.column-content-s {
	$this: &;

	&.filter-active {
		#{$this}__sidebar {
			display: none;
		}

		#{$this}__content {
			width: 100%;
		}

		#{$this}__col {
			width: 25%;

			@include breakpoint(1024px down) {
				margin-bottom: 2.4rem;
				width: 50%;
			}

			@include breakpoint(767px down) {
				padding: 0;
				width: 100%;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}

		#{$this}__col-img {
			@extend %background-image;
			height: 19rem;
			width: 100%;

			@include breakpoint(1024px down) {
				height: 28rem;
			}

			@include breakpoint(1023px down) {
				height: 23.5rem;
			}
		}

		#{$this}__col-content {
			height: calc(100% - 19rem);
			padding: 3.5rem 2rem;

			@include breakpoint(1024px down) {
				height: calc(100% - 28rem);
			}

			@include breakpoint(1023px down) {
				height: calc(100% - 23.5rem);
			}
		}
	}

	&__row {
		display: flex;
		flex-flow: row wrap;
		margin-left: -1.25rem;
		width: calc(100% + 2.5rem);

		@include breakpoint(1023px down) {
			margin-left: 0;
			width: 100%;
		}
	}

	&__sidebar {
		flex: 0 1 auto;
		box-sizing: border-box;
		overflow: hidden;
		padding: 0 1.25rem;
		width: 25%;

		@include breakpoint(1023px down) {
			margin-bottom: 2.4rem;
			padding: 0;
			width: 100%;
		}
	}

	&__content {
		flex: 0 1 auto;
		width: 75%;

		@include breakpoint(1023px down) {
			width: 100%;
		}
	}

	&__top {
		flex: 0 1 auto;
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		margin: 1rem 0 3rem;
		padding: 0 1.25rem;
		width: 100%;

		@include breakpoint(1023px down) {
			padding: 0;
			margin: 0 0 3rem;
		}
	}

	&__title {
		@extend %o-title-inter-semibold-28;
		flex: 1 1 0;
		color: $color-gray-4;
		line-height: 1.2;
		margin-bottom: 0;
		margin-top: 0;
	}

	&__cols {
		display: flex;
		flex-flow: row wrap;

		@include breakpoint(1023px down) {
			margin-left: -1.25rem;
			width: calc(100% + 2.5rem);
		}

		@include breakpoint(767px down) {
			margin-left: 0;
			width: 100%;
		}
	}

	&__col {
		flex: 0 1 auto;
		display: flex;
		flex-flow: row wrap;
		padding: 0 1.25rem;
		width: 33.33%;

		&.d-block {
			display: block;
		}

		&.full {
			width: 100% !important;
		}

		@include breakpoint(1023px down) {
			margin-bottom: 2.4rem;
			width: 50%;
		}

		@include breakpoint(767px down) {
			padding: 0;
			width: 100%;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__loader {
		display: none;
		text-align: center;
	}

	&__col-message {
		@extend %o-title-inter-bold-18;
		color: $color-gray-5;
		line-height: 1.2;
		margin: 0;
		text-align: center;
	}
}
