.flexspace {
	display: none;

	&.desktop {
		@media only screen and (min-width: 1025px) {
			display: block;
		}
	}

	&.tablet {
		@media only screen and (min-width: 768px) and (max-width: 1024px) {
			display: block;
		}
	}

	&.mobile {
		@media only screen and (max-width: 767px) {
			display: block;
		}
	}
}
