%header-me-svg-arrow {
	margin-top: 0.1rem;
	margin-left: 1rem;
	position: absolute;
	top: 50%;
	right: -2rem;
	transform: translateY(-50%);
	transition: right 0.3s ease;
}

%header-me-svg-arrow-container {
	color: inherit;
	font: inherit;
	position: relative;
}

%header-me-svg-arrow-hover {
	position: relative;

	&:hover {
		> svg {
			right: -3rem;
		}
	}
}

.btn {
	$trans: 0.3s ease 0s;

	background-color: $color-orange-1;
	border: 0.2rem solid $color-orange-1;
	border-radius: 10rem;
	box-sizing: border-box;
	color: $color-white-1;
	display: inline-block;
	font-family: $font-inter;
	font-size: 1.6rem;
	font-weight: 700;
	line-height: 1.2;
	min-width: 19.5rem;
	padding: 1rem 2rem;
	text-align: center;
	text-decoration: none;
	transition: background-color $trans, border-color $trans, color $trans;

	&:hover {
		background-color: $color-green-1;
		border-color: $color-white-1;
		color: $color-white-1;
		text-decoration: none;
	}

	&:focus {
		color: $color-white-1;
	}

	&--dark {
		&:hover {
			background-color: $color-white-1;
			border-color: transparent;
			color: $color-green-1;
		}
	}
}

.link-arrow {
	color: $color-night;
	font-family: $font-inter;
	font-size: 1.4rem;
	font-weight: 700;
	line-height: 1.2;
	position: relative;
	text-decoration: none;

	&:hover {
		color: $color-night !important;
		text-decoration: none;
	}

	span {
		@extend %header-me-svg-arrow-container;
	}

	svg {
		@extend %header-me-svg-arrow;
	}
}

.btn--green {
	background-color: $color-green-1;
	border-color: $color-green-1;
	color: $color-white-1;

	&:hover {
		background-color: $color-orange-1;
		border-color: $color-orange-1;
		color: $color-white-1;
	}

	&:focus {
		color: $color-white-1;
	}
}

.btn--white {
	background-color: $color-white-1;
	border-color: $color-white-1;
	color: $color-green-1;

	&:hover {
		background-color: $color-orange-1;
		border-color: $color-orange-1;
		color: $color-white-1;
	}

	&:focus {
		color: $color-green-1;
	}
}

.hs-button {
	background-color: $color-white-1;
	border: 0.2rem solid $color-white-1;
	border-radius: 10rem;
	box-sizing: border-box;
	color: $color-green-5;
	display: inline-block;
	font-family: $font-inter;
	font-size: 1.6rem;
	font-weight: 700;
	line-height: 1.2;
	min-width: 22.4rem;
	padding: 1rem 2rem;
	text-decoration: none;
	transition: all 0.3s;

	&:hover {
		background-color: transparent;
		border-color: $color-white-1;
		color: $color-white-1;
		text-decoration: none;
	}

	&:focus {
		color: $color-green-5;
	}
}

.btn-outline {
	@extend .btn;
	background-color: transparent;
	border-color: $color-white-1;

	&:hover {
		background-color: $color-white-1;
		color: $color-gray-5;
	}

	&:focus {
		color: $color-white-1;
	}
}

.btn-link {
	color: $color-pumpkin;
	font-family: $font-inter;
	font-size: 1.6rem;
	font-weight: 700;
	line-height: 1.2;
	opacity: 1;
	text-decoration: none;
	transition: all 0.3s;

	&:hover {
		color: $color-pumpkin;
		opacity: 0.5;
		text-decoration: none;
	}

	&:focus {
		color: $color-pumpkin;
	}
}

.btn-arrow {
	color: $color-gray-4;
	display: inline-block;
	font-family: $font-inter;
	font-size: 2.4rem;
	font-weight: 400;
	line-height: 1.2;
	opacity: 1;
	padding-right: 3.5rem;
	position: relative;
	text-decoration: none;
	transition: all 0.3s;

	&:before {
		content: url("../images/arrow-right-ee7c32.svg");
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
	}

	&:hover {
		opacity: 0.5;
		text-decoration: none;
	}

	&:focus {
		color: $color-gray-4;
	}
}

.btn-arrow-2 {
	color: $color-orange-1;
	display: inline-block;
	font-family: $font-inter;
	font-size: 10.2rem;
	font-weight: 700;
	line-height: 1.2;
	opacity: 1;
	padding-right: 2rem;
	position: relative;
	text-decoration: none;
	transition: all 0.3s;

	&:before {
		content: url("../images/arrow-right-ee7c32-2.svg");
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
	}

	&:hover {
		color: $color-orange-1;
		opacity: 0.5;
		text-decoration: none;
	}
}

.btn-play {
	border-radius: 50%;
	background-color: $color-orange-1;
	width: 10.8rem;
	height: 10.8rem;
	position: absolute;
	transition: background-color 0.3s ease 0s;

	&::before {
		border-top: 1.8rem solid transparent;
		border-bottom: 1.8rem solid transparent;
		border-left: 2.4rem solid $color-white-1;
		display: block;
		content: "";
		margin-left: 0.4rem;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}