// COLUMN CONTENT - ICON CONTENT BOX 3 - DEFAULT
.o-cc-icb3-d {
	background-color: $color-floral-white;
	border-radius: 15px;
	box-shadow: 2px 2px 25px 0px rgba(0, 0, 0, 0.15); 
	overflow: hidden;

	&__img {
		text-align: center;
		padding: 2.5rem 2.5rem 0;

		img {
			width: auto;
		}
	}

	&__content {
		background-color: $color-white-1;
		padding: 2.5rem;
	}

	&__text {
		p {
			color: $color-onyx;
			font-size: 1.8rem;
			font-weight: 600;
			line-height: 1.5;
		}
	}
}
