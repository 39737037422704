.arc-content-d {
	$this: &;

	overflow: hidden;
	position: relative;

	&.arc-left {
		#{$this}__bg {
			right: 0;
		}

		#{$this}__arc {
			left: 50%;
		}

		&.bg-width-45p {
			#{$this}__arc {
				margin-left: -32rem;
			}

			#{$this}__num {
				margin-left: 5rem;

				@include breakpoint(1100px down) {
					margin-left: 5%;
				}

				@include breakpoint(1023px down) {
					margin-left: 0;
				}
			}
		}
	}

	&.bg-width-45p {
		#{$this}__bg {
			width: 45%;
		}
	}

	&__bg {
		position: absolute;
		height: 100%;
		top: 0;
		z-index: 1;

		@include breakpoint(1023px down) {
			display: none;
		}
	}

	&__arc {
		border-radius: 100%;
		border: 200rem solid transparent;
		width: 500rem;
		height: 500rem;
		pointer-events: none;
		margin-top: 100rem;
		position: absolute;
		top: 50%;
		z-index: 2;
		transform: translateY(-50%);

		@include breakpoint(1023px down) {
			display: none;
		}
	}

	&__grid {
		position: relative;
		z-index: 3;

		@include breakpoint(1023px down) {
			max-width: 100%;
		}
	}

	&__content--builder {
		padding-top: 10rem;
		padding-bottom: 10rem;

		@include breakpoint(1023px down) {
			padding-top: 5rem;
			padding-bottom: 5rem;
		}

		.grid-container {
			@include breakpoint(1024px up) {
				max-width: 100%;
			}
		}

		@include breakpoint(1024px up) {
			&.width-35p {
				width: 35%;
			}
	
			&.width-65p {
				width: 65%;
			}
		}
	}

	&__title {
		@extend %o-title-inter-semibold-40;
		color: $color-charcoal;
		line-height: 1.2;
		margin-bottom: 4rem;
		position: relative;

		&.color-text-white {
			color: $color-white-1;
		}

		&.max-width-400 {
			@include breakpoint(1024px up) {
				max-width: 40rem;
			}
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__text {
		position: relative;
		margin-bottom: 4rem;

		&:last-child {
			margin-bottom: 0;
		}

		&.color-text-midnight-green {
			li,
			p {
				color: $color-midnight-green;
			}
		}

		li,
		p {
			color: $color-charcoal;
			font-size: 2rem;
		}

		.button-box {
			margin-top: 2rem;

			&:first-child {
				margin-top: 0;
			}

			> a {
				margin-right: 1.5rem;

				@include breakpoint(1024px down) {
					margin-bottom: 1.5rem;
					margin-right: 0;
					text-align: center;
				}

				@include breakpoint(1023px down) {
					display: inline-block;
					margin-right: 1.5rem;
					margin-bottom: 0;
				}

				@include breakpoint(767px down) {
					margin-bottom: 1.5rem;
					margin-right: 0;
				}

				&.btn-link {
					margin-left: 3rem;

					@include breakpoint(1024px down) {
						margin-left: 0;
					}

					@include breakpoint(1023px down) {
						margin-left: 3rem;
					}

					@include breakpoint(767px down) {
						margin-left: 3rem;
					}
				}

				&:last-child {
					margin-right: 0;

					@include breakpoint(1024px down) {
						margin-bottom: 0;
					}
				}
			}
		}
	}

	&__num {
		&.color-text-midnight-green {
			li {
				color: $color-midnight-green;
			}

			a {
				&:hover {
					color: $color-pumpkin-2;
				}
			}
		}

		@include breakpoint(1024px up) {
			&.li-count-6 ul li:nth-child(1),
			&.li-count-6 ol li:nth-child(1) {
				margin-left: 26rem;
			}

			&.li-count-5 ul li:nth-child(1),
			&.li-count-5 ol li:nth-child(1),
			&.li-count-6 ul li:nth-child(2),
			&.li-count-6 ol li:nth-child(2) {
				margin-left: 19.5rem;
			}

			&.li-count-4 ul li:nth-child(1),
			&.li-count-4 ol li:nth-child(1),
			&.li-count-5 ul li:nth-child(2),
			&.li-count-5 ol li:nth-child(2),
			&.li-count-6 ul li:nth-child(3),
			&.li-count-6 ol li:nth-child(3) {
				margin-left: 13.5rem;
			}

			&.li-count-3 ul li:nth-child(1),
			&.li-count-3 ol li:nth-child(1),
			&.li-count-4 ul li:nth-child(2),
			&.li-count-4 ol li:nth-child(2),
			&.li-count-5 ul li:nth-child(3),
			&.li-count-5 ol li:nth-child(3),
			&.li-count-6 ul li:nth-child(4),
			&.li-count-6 ol li:nth-child(4) {
				margin-left: 8rem;
			}

			&.li-count-2 ul li:nth-child(1),
			&.li-count-2 ol li:nth-child(1),
			&.li-count-3 ul li:nth-child(2),
			&.li-count-3 ol li:nth-child(2),
			&.li-count-4 ul li:nth-child(3),
			&.li-count-4 ol li:nth-child(3),
			&.li-count-5 ul li:nth-child(4),
			&.li-count-5 ol li:nth-child(4),
			&.li-count-6 ul li:nth-child(5),
			&.li-count-6 ol li:nth-child(5) {
				margin-left: 3rem;
			}
		}

		ul,
		ol {
			counter-reset: item;
			list-style: none;
			margin-left: 0;

			li {
				display: flex;
				align-items: center;
				color: $color-charcoal;
				counter-increment: item;
				padding-left: 10rem;
				min-height: 8rem;
				margin-bottom: 3.5rem;
				position: relative;

				&:last-child {
					margin-bottom: 0;
				}

				&::before {
					background-color: #133535;
					border-radius: 50%;
					display: inline-block;
					content: counter(item);
					color: $color-white-1;
					font-family: $font-inter;
					font-size: 3.6rem;
					font-weight: 600;
					width: 8rem;
					height: 8rem;
					line-height: 8rem;
					text-align: center;
					position: absolute;
					top: 50%;
					left: 0;
					transform: translateY(-50%);
				}
			}
		}

		a {
			color: inherit;
			text-decoration: underline;
		}
	}
}
