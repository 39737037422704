// COLUMN CONTENT - FEATURED - RESOURCES CATEGORY FEATURED
.o-cc-f-rcf {
	background-color: $color-midnight-green;
	border-radius: 1rem;
	box-shadow: 0.2rem 0.2rem 1.5rem 0 rgba(0, 0, 0, 0.25);
	overflow: hidden;

	&__img {
		@extend %background-image;
		background-color: #000;
		width: 45%;
		position: relative;
		z-index: 1;

		@include breakpoint(1023px down) {
			display: none;
		}
	}

	&__content {
		padding: 15.5rem 5rem;
		position: relative;
		z-index: 2;

		@include breakpoint(1024px up) {
			width: 55%;
		}

		@include breakpoint(1023px down) {
			padding: 8rem;
		}

		@include breakpoint(767px down) {
			padding: 6rem;
		}

		&::before {
			display: block;
			border-radius: 100%;
			border: 50rem solid transparent;
			border-color: $color-midnight-green;
			content: "";
			width: 200rem;
			height: 200rem;
			pointer-events: none;
			position: absolute;
			top: -48rem;
			left: -15rem;
			z-index: 1;

			@include breakpoint(1023px down) {
				display: none;
			}
		}
	}

	&__content-wrap {
		position: relative;
		z-index: 2;
	}

	&__cat {
		@extend %o-text-inter-regular-16;
		color: $color-white-1;
		line-height: 1.2;
		letter-spacing: 0.5rem;
		margin-bottom: 1.5rem;
	}

	&__border {
		background-color: $color-orange-1;
		height: 0.3rem;
		width: 10.5rem;
		margin-bottom: 1.5rem;
	}

	&__title {
		@extend %o-title-inter-bold-36;
		color: $color-white-1;
		line-height: 1.2;
		margin-bottom: 2.5rem;
	}
}
