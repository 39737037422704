// COLUMN CONTENT - TESTIMONIAL - DEFAULT
.o-cc-t-d {
	border: 1px solid $color-white-1;
	border-radius: 1.5rem;
	padding: 4.5rem;

	@include breakpoint(767px down) {
		padding: 3rem;
	}

	&__sym {
		margin-bottom: 2.5rem;

		@include breakpoint(767px down) {
			margin-bottom: 0;
		}

		svg {
			width: 5rem;

			@include breakpoint(767px down) {
				width: 3rem;
			}
		}
	}

	&__text {
		p {
			color: $color-white-1;
			font-size: 1.8rem;
			font-weight: 600;
			line-height: 1.3;
		}
	}

	&__user {
		margin-top: 2.5rem;
	}

	&__user-img {
		padding-right: 1.5rem;

		@include breakpoint(768px up) {
			@include shrink();
		}

		@include breakpoint(767px down) {
			padding-right: 0;
		}

		img {
			width: 5.5rem;
		}
	}

	&__user-text {
		@include breakpoint(768px up) {
			@include auto();
		}

		@include breakpoint(767px down) {
			margin-top: 1rem;
		}

		p {
			color: $color-white-1;
			font-size: 1.6rem;
			line-height: 1.3;
		}
	}
}
