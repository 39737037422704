.testimonial-d {
	$this: &;

	&__grid {
		position: relative;
	}

	&__swiper {
		padding: 0 5rem;
	}

	&__swiper-slide {
		text-align: center;
	}

	&__swiper-button-prev,
	&__swiper-button-next {
		&:after {
			display: none;
		}
	}

	&__swiper-button-prev {
		left: 0;
	}

	&__swiper-button-next {
		right: 0;
	}

	&__img {
		margin-bottom: 2rem;
	}

	&__text {
		margin-bottom: 2rem;

		p {
			color: $color-gray-10;
			font-size: 2.4rem;
			font-weight: 600;
			line-height: 1.3;

			@include breakpoint(767px down) {
				font-size: 2rem;
			}
		}
	}

	&__author {
		margin-bottom: 2rem;

		p {
			strong {
				font-weight: 600;
			}
		}
	}
}
