.o-cc-ca-ic1 {
	$this: &;
	$trans: 0.4s ease 0s;

	border-radius: 1.5rem;
	border: 2px solid $color-white-1;
	height: 100%;
	padding: 4.2rem;
	position: relative;
	transform: scale(1);
	transition: transform $trans;

	&:hover {
		transform: scale(1.05);
	}

	&.has-link {
		#{$this}__text {
			padding-bottom: 5rem;
		}
	}

	@include breakpoint(1023px down) {
		padding: 3.5rem;
	}

	&__content {
		height: 100%;
		position: relative;
	}

	&__title {
		margin-bottom: 4rem;
	}

	&__title-icon {
		img {
			max-width: 5rem;
			width: 100%;
		}
	}

	&__title-text {
		@extend %o-title-inter-semibold-18;
		color: $color-charcoal;
		line-height: 1.5;
		padding-left: 2rem;
		margin-bottom: 0;
	}

	&__text {
		h1,
		h2,
		h3 {
			@extend %o-title-inter-semibold-24;
			margin-bottom: 2.5rem;
		}

		p {
			line-height: 1.5;
		}

		ul {
			li {
				color: $color-charcoal;
				line-height: 1.5;
				margin-left: 1.5rem;
				margin-bottom: 0;
				list-style-position: outside;

				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}

	&__link {
		position: absolute;
		left: 0;
		bottom: 0;

		a {
			display: inline-block;
			color: $color-charcoal;
			font-size: 1.6rem;
			font-weight: 600;
			padding-right: 4rem;
			position: relative;

			svg {
				position: absolute;
				top: 50%;
				right: 0;
				transform: translateY(-50%);
			}
		}
	}
}
