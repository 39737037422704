// COLUMN CONTENT - CONTENT CELLS - CELL BOX
%o-cc-cc-cb-btns {
	ul {
		margin-left: 0;

		li {
			border: 1px solid $color-platinum;
			border-radius: 0.5rem;
			display: inline-block;
			font-size: 1rem;
			padding: 0.5rem 2rem;
			margin-bottom: 0.8rem;
			margin-right: 0.8rem;

			&:last-child {
				margin-right: 0;
			}
		}
	}
}

.o-cc-cc-cb {
	$this: &;

	background-color: $color-white-1; 
	border-radius: 1.5rem;
	box-shadow: 2px 2px 25px 0px rgba(0, 0, 0, 0.15);
	padding: 3.5rem;

	> * {
		margin-bottom: 2rem;

		&:not(#{$this}__title) {
			margin-left: 2rem;
			margin-right: 2rem;
		}
	}

	&__title {
		border-bottom: 1px solid $color-platinum;
		padding-bottom: 2rem;
	}

	&__title-text {
		@extend %o-title-inter-semibold-24;
		padding-left: 1.2rem;
		margin-bottom: 0;
	}

	&__notice {
		background-color: $color-anti-flash-white;
		border-radius: 0.5rem;

		ul {
			padding-top: 1.2rem;
			padding-bottom: 1.2rem;

			li {
				color: $color-charcoal;
				font-size: 1rem;
				line-height: 1.5;
				padding-left: 2.6rem;
				list-style-type: none;
				position: relative;

				&::before {
					background-color: $color-cadet-gray;
					border-radius: 50%;
					display: block;
					content: '';
					width: 1.1rem;
					height: 1.1rem;
					margin-top: -0.55rem;
					position: absolute;
					top: 50%;
					left: 0.5rem;
				}
			}
		}
	}

	&__btns {
		@extend %o-cc-cc-cb-btns;
	}

	&__qu1 {
		@extend %o-cc-cc-cb-btns;
		background-color: $color-anti-flash-white;
		border-radius: 0.5rem;
		padding: 1.2rem;

		h2,
		h3 {
			@extend %o-title-inter-semibold-12;
			line-height: 1.5;
			margin-bottom: 1.2rem;
		}
	}

	&__pay {
		background-color: $color-anti-flash-white;
		border-radius: 0.5rem;
		padding: 1.2rem 1.2rem 3rem;

		h2,
		h3 {
			@extend %o-title-inter-semibold-12;
			margin-bottom: 1.2rem;
		}

		p {
			color: $color-charcoal;
			font-size: 1.2rem;
			line-height: 1.5;
		}

		svg {
			display: inline-block;
			margin-right: 0.5rem;
			vertical-align: middle;
		}
	}

	&__border {
		border-bottom: 1px solid $color-platinum;
		margin-top: 0.8rem;
	}

	&__text {
		p {
			color: $color-charcoal;
			font-size: 1.2rem;
			line-height: 1.5;
		}
	}
}
