.unique-a {
	$this: &;

	position: relative;

	&__grid {
		padding: 12rem 15% 12rem 10rem;
		position: relative;
		z-index: 2;

		@include breakpoint(1200px down) {
			padding: 10rem 5rem;
		}

		@include breakpoint(1023px down) {
			padding: 5rem;
		}
	}

	&__cell {
		> * {
			margin-bottom: 10rem;

			@include breakpoint(1023px down) {
				margin-bottom: 5rem;
			}

			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__cl-row {
		$gap: 2rem;

		margin-left: -$gap;
		margin-right: -$gap;

		@include breakpoint(1023px down) {
			margin-left: 0;
			margin-right: 0;
		}
	}

	&__cl-col {
		$gap: 2.5rem;

		padding-left: $gap;
		padding-right: $gap;

		&.col-width-40 {
			@include breakpoint(1024px up) {
				width: 40%;
			}
		}

		&.col-width-60 {
			@include breakpoint(1024px up) {
				width: 60%;
			}
		}

		@include breakpoint(1023px down) {
			padding-left: 0;
			padding-right: 0;
			margin-bottom: 5rem;
		}

		&:last-child {
			margin-bottom: 0;

			#{$this}__cl-col-text {
				&::after {
					display: none;
				}
			}
		}
	}

	&__cl-col-text {
		height: 100%;
		position: relative;

		@include breakpoint(1024px up) {
			&::after {
				background-color: $color-xanthous;
				display: block;
				content: "";
				width: 0.2rem;
				height: 100%;
				position: absolute;
				top: 0;
				right: -0.1rem;
			}
		}
	}

	&__cl-col-text,
	&__text {
		h2,
		h3 {
			@extend %o-title-inter-bold-54;
			color: $color-midnight-green;
			line-height: 1.25;
			margin-bottom: 1.5rem;
		}

		p {
			color: $color-midnight-green;
			font-size: 1.8rem;
			line-height: 1.5;
		}
	}

	&__paint {
		width: 50%;
		height: 100%;
		position: absolute;
		top: 0;
		right: 0;
		z-index: 1;
	}

	&__img {
		position: absolute;
		top: 50%;
		right: 0;
		z-index: 3;
		transform: translateY(-50%);

		@include breakpoint(1200px down) {
			display: none;
		}
	}
}
