.o-elem-ccd-icd {
	border-radius: 2.4rem;
	box-shadow: 0 0 8px 2px rgba(0, 0, 0, 0.1);
	height: 100%;
	padding: 2rem;

	> * {
		margin-bottom: 2rem;

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__icon {
		margin-bottom: 2rem;

		img {
			display: block;
			height: 5.5rem;
			margin-left: auto;
			margin-right: auto;
		}
	}
}
