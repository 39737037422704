%o-text-inter-semibold-18 {
	font-family: $font-inter;
	font-size: 18px;
	font-weight: 600;
}

%o-text-inter-bold-48 {
	font-family: $font-inter;
	font-size: 48px;
	font-weight: 700;
}

%o-text-inter-bold-33 {
	font-family: $font-inter;
	font-size: 33px;
	font-weight: 700;
}

%o-text-inter-bold-24 {
	font-family: $font-inter;
	font-size: 24px;
	font-weight: 700;
}

%o-text-inter-bold-18 {
	font-family: $font-inter;
	font-size: 18px;
	font-weight: 700;
}

%o-text-inter-bold-16 {
	font-family: $font-inter;
	font-size: 16px;
	font-weight: 700;
}

%o-text-inter-regular-24 {
	font-family: $font-inter;
	font-size: 24px;
	font-weight: 400;
}

%o-text-inter-regular-18 {
	font-family: $font-inter;
	font-size: 18px;
	font-weight: 400;
}

%o-text-inter-regular-16 {
	font-family: $font-inter;
	font-size: 16px;
	font-weight: 400;
}

%o-text-inter-regular-14 {
	font-family: $font-inter;
	font-size: 14px;
	font-weight: 400;
}

%o-text-inter-regular-12 {
	font-family: $font-inter;
	font-size: 12px;
	font-weight: 400;
}

%o-text-inter-light-18 {
	font-family: $font-inter;
	font-size: 18px;
	font-weight: 300;
}

%o-text-inter-light-16 {
	font-family: $font-inter;
	font-size: 16px;
	font-weight: 300;
}

%o-text-opensans-regular-16 {
	font-family: "Open Sans";
	font-size: 16px;
	font-weight: 400;
}
