// COLUMN CONTENT - FEATURED - RESOURCES PAGE FEATURED
.o-cc-f-rpf {
	background-color: $color-floral-white;
	border-radius: 1.5rem;
	box-shadow: 0.2rem 0.2rem 1.5rem 0 rgba(0, 0, 0, 0.25);
	height: 100%;
	margin-bottom: 2.4rem;
	overflow: hidden;
	padding: 7.5rem 6.5rem;
	position: relative;

	@include breakpoint(767px down) {
		padding: 3rem;
	}

	&__bg {
		@extend %background-image;
		background-color: #133535;
		width: 45%;
		height: 30rem;
		overflow: hidden;
		position: absolute;
		bottom: 0;
		right: 0;
		z-index: 1;

		&::before {
			display: block;
			border-radius: 100%;
			border: 50rem solid transparent;
			border-color: $color-floral-white;
			content: "";
			width: 200rem;
			height: 200rem;
			pointer-events: none;
			position: absolute;
			top: -54rem;
			left: -52rem;
			z-index: 1;
		}

		@include breakpoint(1023px down) {
			display: none;
		}

		img {
			display: block;
			position: relative;
		}
	}

	&__content {
		position: relative;
		z-index: 2;
	}

	&__cat {
		@extend %o-text-inter-regular-18;
		color: $color-midnight-green;
		line-height: 1.2;
		letter-spacing: 0.2rem;
		margin-bottom: 2rem;
	}

	&__border {
		background-color: $color-orange-1;
		height: 0.2rem;
		width: 10rem;
		margin-bottom: 2rem;
	}

	&__title {
		@extend %o-title-inter-bold-38;
		color: $color-midnight-green;
		line-height: 1.2;
		margin-bottom: 4rem;

		@include breakpoint(1024px up) {
			max-width: 40rem;
		}

		@include breakpoint(767px down) {
			font-size: 3rem;
		}
	}
}
