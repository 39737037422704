// COLUMN CONTENT - STANDARD - SIDEBAR
.o-cc-s-sb {
	$this: &;

	@extend %background-image;
	border-radius: 1rem;
	height: 100%;
	text-align: right;
	overflow: hidden;
	position: relative;

	.btn {
		position: relative;
	}

	&__content {
		@include breakpoint(1024px up) {
			padding: 5rem 3.5rem 8rem 0;
			width: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
			z-index: 2;
		}

		@include breakpoint(1023px down) {
			padding: 5rem 3rem 5rem 5rem;
			position: relative;
			z-index: 2;
		}
	}

	&__supertitle {
		@extend %o-text-inter-regular-16;
		color: $color-white-1;
		line-height: 1.2;
		margin-bottom: 1rem;
	}

	&__title {
		@extend %o-title-inter-semibold-24;
		color: $color-white-1;
		line-height: 1.2;
		margin-bottom: 1.5rem;

		@include breakpoint(1023px down) {
			max-width: 30rem;
			margin-left: auto;
		}
	}

	&__curve {
		background-color: $color-midnight-green;
		border-radius: 100%;
		width: 100rem;
		height: 100rem;
		pointer-events: none;
		position: absolute;
		z-index: 1;

		@include breakpoint(1024px up) {
			top: 0;
			left: -1rem;
		}

		@include breakpoint(1023px down) {
			top: -10rem;
			right: -65rem;
		}

		@include breakpoint(767px down) {
			left: 0;
			right: auto;
		}

		@include breakpoint(480px down) {
			top: -20rem;
		}
	}
}
