.o-number-orange {
	ol {
		counter-reset: number;

		li {
			color: $color-gray-9;
			counter-increment: number;
			list-style: none;
			margin-bottom: 30px !important;
			padding-left: 40px;
			position: relative;

			&:last-child {
				margin-bottom: 0 !important;
			}

			&:before {
				background-color: $color-orange-1;
				border-radius: 100%;
				color: $color-white-1;
				content: counter(number);
				font-family: $font-inter;
				font-size: 14px;
				font-weight: 500;
				height: 30px;
				left: 0;
				line-height: 30px;
				position: absolute;
				text-align: center;
				top: 50%;
				width: 30px;
				transform: translateY(-50%);
			}
		}
	}
}
