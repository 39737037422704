%o-title-inter-light-36 {
	font-family: $font-inter;
	font-size: 3.6rem;
	font-weight: 300;
}

%o-title-inter-regular-22 {
	font-family: $font-inter;
	font-size: 2.2rem;
	font-weight: 400;
}

%o-title-inter-medium-14 {
	font-family: $font-inter;
	font-size: 1.4rem;
	font-weight: 500;
}

%o-title-inter-medium-16 {
	font-family: $font-inter;
	font-size: 1.6rem;
	font-weight: 500;
}

%o-title-inter-medium-18 {
	font-family: $font-inter;
	font-size: 1.8rem;
	font-weight: 500;
}

%o-title-inter-medium-22 {
	font-family: $font-inter;
	font-size: 2.2rem;
	font-weight: 500;
}

%o-title-inter-medium-36 {
	font-family: $font-inter;
	font-size: 3.6rem;
	font-weight: 500;
}

%o-title-inter-semibold-12 {
	font-family: $font-inter;
	font-size: 1.2rem;
	font-weight: 600;
}

%o-title-inter-semibold-16 {
	font-family: $font-inter;
	font-size: 1.6rem;
	font-weight: 600;
}

%o-title-inter-semibold-18 {
	font-family: $font-inter;
	font-size: 1.8rem;
	font-weight: 600;
}

%o-title-inter-semibold-22 {
	font-family: $font-inter;
	font-size: 2.2rem;
	font-weight: 600;
}

%o-title-inter-semibold-24 {
	font-family: $font-inter;
	font-size: 2.4rem;
	font-weight: 600;
}

%o-title-inter-semibold-28 {
	font-family: $font-inter;
	font-size: 2.8rem;
	font-weight: 600;
}

%o-title-inter-semibold-36 {
	font-family: $font-inter;
	font-size: 3.6rem;
	font-weight: 600;
}

%o-title-inter-semibold-40 {
	font-family: $font-inter;
	font-size: 4rem;
	font-weight: 600;
}

%o-title-inter-semibold-54 {
	font-family: $font-inter;
	font-size: 5.4rem;
	font-weight: 600;

	@include breakpoint(1023px down) {
		font-size: 5rem;
	}
}

%o-title-inter-semibold-55 {
	font-family: $font-inter;
	font-size: 5.5rem;
	font-weight: 600;

	@include breakpoint(1023px down) {
		font-size: 5rem;
	}

	@include breakpoint(767px down) {
		font-size: 4rem;
	}
}

%o-title-inter-bold-12 {
	font-family: $font-inter;
	font-size: 1.2rem;
	font-weight: 700;
}

%o-title-inter-bold-14 {
	font-family: $font-inter;
	font-size: 1.4rem;
	font-weight: 700;
}

%o-title-inter-bold-16 {
	font-family: $font-inter;
	font-size: 1.6rem;
	font-weight: 700;
}

%o-title-inter-bold-18 {
	font-family: $font-inter;
	font-size: 1.8rem;
	font-weight: 700;
}

%o-title-inter-bold-20 {
	font-family: $font-inter;
	font-size: 2rem;
	font-weight: 700;
}

%o-title-inter-bold-22 {
	font-family: $font-inter;
	font-size: 2.2rem;
	font-weight: 700;
}

%o-title-inter-bold-24 {
	font-family: $font-inter;
	font-size: 2.4rem;
	font-weight: 700;
}

%o-title-inter-bold-28 {
	font-family: $font-inter;
	font-size: 2.8rem;
	font-weight: 700;
}

%o-title-inter-bold-30 {
	font-family: $font-inter;
	font-size: 3rem;
	font-weight: 700;
}

%o-title-inter-bold-32 {
	font-family: $font-inter;
	font-size: 3.2rem;
	font-weight: 700;
}

%o-title-inter-bold-34 {
	font-family: $font-inter;
	font-size: 3.4rem;
	font-weight: 700;
}

%o-title-inter-bold-35 {
	font-family: $font-inter;
	font-size: 3.5rem;
	font-weight: 700;
}

%o-title-inter-bold-36 {
	font-family: $font-inter;
	font-size: 3.6rem;
	font-weight: 700;
}

%o-title-inter-bold-38 {
	font-family: $font-inter;
	font-size: 3.8rem;
	font-weight: 700;
}

%o-title-inter-bold-40 {
	font-family: $font-inter;
	font-size: 4rem;
	font-weight: 700;
}

%o-title-inter-bold-42 {
	font-family: $font-inter;
	font-size: 4.2rem;
	font-weight: 700;
}

%o-title-inter-bold-54 {
	font-family: $font-inter;
	font-size: 5.4rem;
	font-weight: 700;

	@include breakpoint(1023px down) {
		font-size: 4.6rem;
	}

	@include breakpoint(767px down) {
		font-size: 3.8rem;
	}
}

%o-title-inter-bold-65 {
	font-family: $font-inter;
	font-size: 6.5rem;
	font-weight: 700;
}

%o-title-inter-extrabold-36 {
	font-family: $font-inter;
	font-size: 3.6rem;
	font-weight: 800;
}

%o-title-inter-extrabold-65 {
	font-family: $font-inter;
	font-size: 6.5rem;
	font-weight: 800;
}
