.unique-s {
	$this: &;

	// oocss
	&__title {
		color: $color-onyx;
		font-family: $font-inter;
		font-size: 4rem;
		line-height: 1.3;

		@include breakpoint(767px down) {
			font-size: 3rem;
		}
	}

	&__text {
		h2,
		h3 {
			@extend %o-title-inter-semibold-40;
			color: $color-onyx;
		}

		p {
			color: $color-onyx;
			font-size: 2rem;
			line-height: 1.5;
		}
	}

	&__icon-circle {
		border-radius: 50%;
	}

	&__phone {
		@for $i from 2 through 10 {
			&.s#{$i} {
				#{$this}__phone-slider {
					transform: translateX(-#{($i - 1) * 100%});
				}
			}
		}

		@include breakpoint(1023px down) {
			margin-bottom: 3rem;
		}
	}

	&__phone-body {
		position: relative;
		z-index: 2;
	}

	&__phone-container {
		border-radius: 6rem;
		overflow: hidden;
	}

	&__phone-screen {
		margin: 0.5rem;
		overflow: hidden;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
	}

	&__phone-slider {
		flex-flow: row nowrap;
		@include transition(transform 0.4s ease 0s);

		img {
			width: 100%;
		}
	}

	// MAIN
	&__grid {
		@media screen and (max-width: 1279px) and (min-width: 1024px) {
			max-width: 91.6666666667%;
		}
	}

	// DESKTOP
	&__desktop {
		height: 200rem;

		@include breakpoint(1023px down) {
			display: none;
		}

		#{$this}__sticky {
			position: sticky;
			top: 15rem;
		}

		#{$this}__header {
			margin-bottom: 5rem;

			#{$this}__title {
				opacity: 0;
				transform: translateY(48px);
				transition:
					opacity 1s ease 0s,
					transform 1s ease 0s;

				&.appear {
					opacity: 1;
					transform: translateY(0);
				}
			}
		}

		#{$this}__list-content {
			opacity: 0;
			transform: translateY(48px);
			transition:
				opacity 1s ease 0s,
				transform 1s ease 0s;

			&.appear {
				opacity: 1;
				transform: translateY(0);
			}
		}

		#{$this}__item {
			position: relative;

			@for $i from 1 through 10 {
				$nth: 11 - $i;

				&:nth-child(#{$i}) {
					z-index: #{$i};
				}
			}

			&:last-child {
				#{$this}__icon-bar {
					display: none;
				}
			}

			&.idle {
				#{$this}__icon-circle-box {
					width: 6.5rem;
					height: 6.5rem;

					svg {
						fill: #fff;
						stroke: #fff;
					}
				}

				#{$this}__icon-white {
					opacity: 1;
				}

				#{$this}__icon-color {
					opacity: 0;
				}
			}

			&.active {
				#{$this}__icon-circle-box {
					width: 6.5rem;
					height: 6.5rem;

					svg {
						fill: #fff;
						stroke: #fff;
					}
				}

				#{$this}__icon-white {
					opacity: 1;
				}

				#{$this}__icon-color {
					opacity: 0;
				}

				#{$this}__desc-header {
					#{$this}__title {
						color: #282828;
					}
				}
			}
		}

		#{$this}__icon {
			margin-right: 4rem;
		}

		#{$this}__icon-circle {
			width: 6.5rem;
			height: 6.5rem;
		}

		#{$this}__icon-circle-box {
			background-color: $color-hookers-green;
			border: 0.5rem solid #e7ebeb;
			border-radius: 50%;
			width: 4.5rem;
			height: 4.5rem;
			position: relative;
			transition:
				background-color 0.4s ease 0s,
				width 0.4s ease 0s,
				height 0.4s ease 0s;

			svg {
				display: block;
				color: #fff;
				fill: #fff;
				stroke: #fff;
				transition: background-color 0.4s ease 0s;
			}
		}

		#{$this}__icon-white,
		#{$this}__icon-color {
			opacity: 1;
			position: absolute;
			top: 50%;
			left: 50%;
			z-index: 2;
			transform: translate(-50%, -50%);
			transition: opacity 0.4s ease 0s;
		}

		#{$this}__icon-bar {
			width: 100%;
			height: calc(100% + 1rem);
			margin-bottom: -1rem;
			position: relative;

			&-line,
			&-fill {
				width: 2px;
				position: absolute;
				top: 0;
				left: 50%;
				margin-left: -1px;
			}
			&-line {
				background-color: #fff7f6;
				height: 100%;
				z-index: 1;
			}

			&-fill {
				background: $color-hookers-green;
				z-index: 2;
			}
		}

		#{$this}__desc {
			padding-bottom: 5rem;
		}

		#{$this}__desc-header {
			height: 7rem;
			justify-content: center;

			#{$this}__title {
				color: #e0e0e0;
				font-size: 2.4rem;
				margin-bottom: 0.5rem;
				transition: color 0.4s ease 0s;
			}
		}

		#{$this}__desc-text {
			display: none;

			p {
				color: $color-onyx;
				font-size: 1.6rem;
				line-height: 1.5;
			}

			a {
				color: $color-charcoal;
				font-family: $font-inter;
				font-size: 1.6rem;
				text-decoration: none;
			}
		}

		#{$this}__phone {
			padding-left: 2rem;
			opacity: 0;
			transform: translateY(48px);
			transition:
				opacity 1s ease 0s,
				transform 1s ease 0s;

			&.appear {
				opacity: 1;
				transform: translateY(0);
			}
		}

		#{$this}__phone-container {
			position: relative;
		}
	}

	// MOBILE
	&__mobile {
		@include breakpoint(1024px up) {
			display: none;
		}

		#{$this}__sticky {
			padding-top: 5rem;
			position: sticky;
			top: 10rem;

			@include breakpoint(599px down) {
				top: 15rem;
			}

			@include breakpoint(479px down) {
				top: 20rem;
			}
		}

		#{$this}__phone {
			text-align: center;
			margin-bottom: 3rem;

			@media screen and (max-height: 600px) and (max-width: 1023px) {
				display: none;
			}

			@include breakpoint(600px down) {
				margin-bottom: 2rem;
			}

			opacity: 0;
			transform: translateY(48px);
			transition:
				opacity 1s ease 0s,
				transform 1s ease 0s;

			&.appear {
				opacity: 1;
				transform: translateY(0);
			}
		}

		#{$this}__phone-container {
			border-radius: 3rem;
			display: inline-block;
			max-width: 200px;
			margin: 0 auto;
			position: relative;

			@include breakpoint(600px down) {
				border-radius: 2rem;
				max-width: none;
			}

			@include breakpoint(399px down) {
				border-radius: 1rem;
			}
		}

		#{$this}__phone-body,
		#{$this}__phone-slider {
			@include breakpoint(600px down) {
				img {
					max-height: 40vh;
				}
			}

			@include breakpoint(399px down) {
				img {
					max-height: 30vh;
				}
			}
		}

		#{$this}__icon-slider {
			opacity: 0;
			transform: translateY(48px);
			transition:
				opacity 1s ease 0s,
				transform 1s ease 0s;

			&.appear {
				opacity: 1;
				transform: translateY(0);
			}
		}

		#{$this}__icon {
			width: 120px;
			margin-bottom: 3rem;

			@include breakpoint(600px down) {
				width: 100px;
				margin-bottom: 2rem;
			}

			&:last-child {
				width: auto;

				#{$this}__icon-bar {
					display: none;
				}
			}

			&.idle {
				#{$this}__icon-circle-box {
					svg {
						fill: #fff;
						stroke: #fff;
					}
				}

				#{$this}__icon-white {
					opacity: 1;
				}

				#{$this}__icon-color {
					opacity: 0;
				}
			}

			&.active {
				#{$this}__icon-circle-box {
					width: 6.5rem;
					height: 6.5rem;

					svg {
						fill: #fff;
						stroke: #fff;
					}
				}

				#{$this}__icon-white {
					opacity: 1;
				}

				#{$this}__icon-color {
					opacity: 0;
				}
			}
		}

		#{$this}__icon-circle {
			background-color: $color-hookers-green;
			width: 4rem;
			height: 4rem;
			position: relative;
			transition: background-color 0.4s ease 0s;

			@include breakpoint(600px down) {
				width: 3rem;
				height: 3rem;
			}

			svg {
				display: block;
				color: #fff;
				fill: #fff;
				stroke: #fff;
				transition: background-color 0.4s ease 0s;
			}
		}

		#{$this}__icon-white,
		#{$this}__icon-color {
			opacity: 1;
			position: absolute;
			top: 50%;
			left: 50%;
			z-index: 2;
			transform: translate(-50%, -50%);
			transition: opacity 0.4s ease 0s;
		}

		#{$this}__icon-bar {
			width: 100%;
			height: 100%;
			position: relative;

			&-line,
			&-fill {
				height: 2px;
				position: absolute;
				top: 50%;
				left: 0;
				margin-top: -1px;
			}

			&-line {
				background-color: #fff7f6;
				width: 100%;
				z-index: 1;
			}

			&-fill {
				background: $color-hookers-green;
				z-index: 2;
			}
		}

		#{$this}__item-slider {
			margin: 0 5%;
			overflow: hidden;

			opacity: 0;
			transform: translateY(48px);
			transition:
				opacity 1s ease 0s,
				transform 1s ease 0s;

			&.appear {
				opacity: 1;
				@include transform(translateY(0));
			}

			@for $i from 2 through 10 {
				&.s#{$i} {
					#{$this}__item-slider-container {
						transform: translateX(-#{($i - 1) * 100%});
					}
				}
			}
		}

		#{$this}__item-slider-container {
			flex-flow: row nowrap;
			transition: transform 0.4s ease 0s;
		}

		#{$this}__desc-header {
			text-align: center;
			margin-bottom: 1rem;

			#{$this}__title {
				font-size: 1.6rem;
				margin-bottom: 0.5rem;
			}
		}

		#{$this}__desc-text {
			text-align: center;

			p {
				font-size: 1.4rem;
				line-height: 1.5;

				strong a {
					color: #2176ff;
					font-family: $font-inter;
					font-size: 1.6rem;
					text-decoration: none;
				}
			}
		}
	}

	&__mobile-header {
		padding-top: 5rem;

		#{$this}__text {
			h2,
			h3 {
				@extend %o-title-inter-semibold-36;
				color: $color-onyx;
			}

			p {
				color: $color-onyx;
				font-size: 1.6rem;
				line-height: 1.5;
			}
		}
	}

	&__mobile-height {
		height: 200rem;
	}
}
