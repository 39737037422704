.elem-column-content-d {
	$this: &;

	&.col-5 {
		#{$this}__col {
			width: 20%;

			@include breakpoint(767px down) {
				width: 100%;
			}
		}
	}

	&.col-gap-10 {
		$gap: 1rem;

		#{$this}__row {
			margin-left: -$gap;
			margin-right: -$gap;
		}

		#{$this}__col {
			padding-left: 1rem;
			padding-right: 1rem;
		}

		&.col-5 {
			@include breakpoint(767px down) {
				#{$this}__col {
					margin-bottom: 1rem;
					padding-left: 0;
					padding-right: 0;

					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}
