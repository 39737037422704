// COLUMN CONTENT - FEATURED - FULL
.o-cc-f-full {
	@extend %background-image;
	border-radius: 1.5rem;
	box-shadow: 0 0.3rem 1.5rem rgba(0, 0, 0, 0.2);
	display: flex;
	flex-flow: row wrap;
	margin-bottom: 2.4rem;

	&:last-child {
		margin-bottom: 0;
	}

	.btn-link {
		border-color: $color-white-1;
		color: $color-white-1;

		&:hover {
			border-bottom-color: $color-orange-1;
			color: $color-orange-1;
		}
	}

	&__img,
	&__content {
		flex: 0 1 auto;
		width: 50%;
	}

	&__img {
		align-self: flex-end;
		bottom: 0;
		left: 0;
		padding-right: 2rem;
		position: relative;

		@include breakpoint(1024px down) {
			display: flex;
			flex-flow: row wrap;
			align-items: flex-end;
		}

		@include breakpoint(1023px down) {
			display: none;
		}

		img {
			border-bottom-left-radius: 1.5rem;
			display: block;
		}
	}

	&__content {
		padding: 4rem;
		position: relative;

		@include breakpoint(1023px down) {
			padding: 8rem;
			width: 100%;
			z-index: 2;
		}

		@include breakpoint(767px down) {
			padding: 6rem;
		}
	}

	&__cat {
		@extend %o-text-inter-regular-18;
		color: $color-white-1;
		line-height: 1.2;
		letter-spacing: 0.2rem;
		margin-bottom: 2rem;
		text-transform: uppercase;
	}

	&__border {
		background-color: $color-orange-1;
		height: 0.2rem;
		width: 10rem;
		margin-bottom: 2rem;
	}

	&__title {
		@extend %o-title-inter-bold-32;
		color: $color-white-1;
		line-height: 1.2;
		margin-bottom: 4rem;
		margin-top: 0;
	}
}
