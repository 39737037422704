// COLUMN CONTENT - STANDARD - POSTS
.o-cc-s-po {
	$this: &;

	border-radius: 0.8rem;
	box-shadow: 0.2rem 0.2rem 1.5rem #e4e8ef;
	overflow: hidden;
	position: relative;
	width: 100%;

	&__img {
		@extend %background-image;
		height: 19.5rem;
		width: 100%;
	}

	&__content {
		background-color: $color-white-1;
		height: calc(100% - 19.5rem);
		padding: 3.5rem 2rem 5rem;

		@include breakpoint(1024px down) {
			padding: 3.5rem 2rem;
		}
	}

	&__cat {
		@extend %o-text-inter-regular-16;
		color: $color-gray-5;
		line-height: 1.2;
		margin-bottom: 1rem;
	}

	&__title {
		@extend %o-title-inter-bold-18;
		color: $color-gray-5;
		line-height: 1.2;
		margin-bottom: 0;
		margin-top: 0;
	}
}
