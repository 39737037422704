.footer {
	$this: &;

	> #{$this}__grid {
		padding: 10rem 0 5.4rem;

		@include breakpoint(1023px down) {
			padding: 8rem 0;
		}

		@include breakpoint(767px down) {
			padding: 5rem 0;
		}
	}

	&__menu {
		padding-right: 5rem;

		@include breakpoint(1024px up) {
			@include auto();
		}

		@include breakpoint(1023px down) {
			padding-right: 0;
			margin-bottom: 5rem;
		}

		@include breakpoint(767px down) {
			margin-bottom: 2rem;
		}
	}

	&__menu-items {
		$gap: 5rem;
		$gap_1023: 2.5rem;
		$gap_767: 1rem;

		margin-left: -$gap;
		margin-right: -$gap;

		@include breakpoint(1023px down) {
			text-align: center;
			justify-content: center;
			margin-left: -$gap_1023;
			margin-right: -$gap_1023;
		}

		@include breakpoint(767px down) {
			margin-left: -$gap_767;
			margin-right: -$gap_767;
		}

		> ul {
			list-style: none;
			margin: 0;

			@include breakpoint(1023px down) {
				justify-content: center;
			}
		}
	}

	&__menu-item {
		$gap: 5rem;
		$gap_1023: 2.5rem;
		$gap_767: 1rem;

		margin-bottom: 0;
		width: 25%;
		padding-left: $gap;
		padding-right: $gap;

		@include breakpoint(1023px down) {
			padding-left: $gap_1023;
			padding-right: $gap_1023;
			margin-bottom: 5rem;
		}

		@include breakpoint(768px up) {
			@include shrink();
		}

		@include breakpoint(767px down) {
			margin-bottom: 4rem;
			padding-left: $gap_767;
			padding-right: $gap_767;
			text-align: left;
			width: 100%;

			&:last-child {
				margin-bottom: 0;
			}
		}

		> a {
			color: $color-gray-10;
			font-family: $font-inter;
			font-size: 1.7rem;
			font-weight: 700;
			letter-spacing: -0.05rem;
			line-height: 1;
			opacity: 1;
			transition: all 0.3s ease;

			&:hover {
				opacity: 0.5;
				text-decoration: none;
			}
		}
	}

	&__submenu {
		list-style: none;
		margin: 0;
		margin-top: 2.5rem;

		> li {
			margin-bottom: 1.5rem;

			&:last-child {
				margin-bottom: 0;
			}

			> a {
				color: $color-gray-10;
				font-family: $font-inter;
				font-size: 1.4rem;
				font-weight: 400;
				line-height: 1;
				opacity: 1;
				transition: all 0.3s ease;

				&:hover {
					opacity: 0.5;
					text-decoration: none;
				}
			}
		}
	}

	&__right {
		@include breakpoint(1024px up) {
			@include shrink();
		}
	}

	&__content {
		margin-bottom: 2.5rem;

		@include breakpoint(1023px down) {
			text-align: center;
		}

		.btn {
			min-width: 19.5rem;
			text-align: center;
		}
	}

	&__address {
		margin-bottom: 2.5rem;
		max-width: 25rem;

		@include breakpoint(1023px down) {
			margin-left: auto;
			margin-right: auto;
			text-align: center;
		}

		p {
			color: $color-gray-10;
			font-size: 1.4rem;
		}
	}

	&__social {
		display: flex;
		flex-flow: row wrap;
		list-style: none;
		margin: 0;

		@include breakpoint(1023px down) {
			justify-content: center;
		}

		> li {
			flex: 0 1 auto;
			margin-bottom: 0;
			margin-right: 1rem;

			&:last-child {
				margin-right: 0;
			}

			a {
				border: 0.1rem solid $color-gray-10;
				border-radius: 100%;
				color: $color-gray-10;
				display: block;
				font-size: 1.8rem;
				height: 3.2rem;
				line-height: 3.2rem;
				text-align: center;
				width: 3.2rem;
				transition: all 0.3s ease;

				&:hover {
					background-color: $color-gray-10;
					color: $color-white-1;
				}
			}
		}
	}

	&__gdpr {
		background-color: $color-black-1;
		padding: 2rem 0;
	}

	&__gdpr-content {
		p {
			color: $color-white-1;
			font-size: 1.6rem;
			font-weight: 700;

			@include breakpoint(767px down) {
				text-align: center;
			}

			a {
				color: $color-orange-3;
				text-decoration: none;
			}
		}
	}
}
