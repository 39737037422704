// PHOTO CONTENT - DEFAULT - ICON LIST
.o-pc-d-mil {
	$this: &;
	$trans: 0.4s ease 0s;

	&:hover {
		#{$this}__icon {
			padding-right: 0.5rem;
		}
	}

	&__icon {
		width: 3rem;
		padding-right: 0;
		transition: padding-right $trans;
	}

	&__text {
		padding-left: 1.5rem;

		p {
			font-size: 1.6rem;
			line-height: 1.25;
		}
	}
}
