.column-content-icb2 {
	$this: &;

	&__row {
		display: flex;
		flex-flow: row wrap;
		margin-left: -0.75rem;
		width: calc(100% + 1.5rem);

		@include breakpoint(767px down) {
			margin-left: 0;
			width: 100%;
		}
	}

	&__col {
		flex: 0 1 auto;
		margin: 0.75rem 0;
		padding: 0 0.75rem;

		@include breakpoint(767px down) {
			padding: 0;
		}

		&.col-45 {
			width: 45%;

			@include breakpoint(1023px down) {
				width: 100%;
			}
		}

		&.col-55 {
			width: 55%;

			@include breakpoint(1023px down) {
				width: 100%;
			}
		}
	}

	&__col-box {
		@extend %background-image;
		display: flex;
		flex-flow: column wrap;
		align-items: flex-start;
		background-color: $color-green-5;
		border-radius: 1.5rem;
		margin-bottom: 1.5rem;
		overflow: hidden;
		padding: 6.5rem 5rem 8rem;
		position: relative;

		@include breakpoint(767px down) {
			padding: 5.5rem 4rem 4rem;
		}

		&.gradient {
			&:before {
				background: rgb(18, 54, 54);
				background: -moz-linear-gradient(
					90deg,
					rgba(18, 54, 54, 1) -3.6%,
					rgba(18, 54, 54, 0.742734593837535) 85.8%
				);
				background: -webkit-linear-gradient(
					90deg,
					rgba(18, 54, 54, 1) -3.6%,
					rgba(18, 54, 54, 0.742734593837535) 85.8%
				);
				background: linear-gradient(
					90deg,
					rgba(18, 54, 54, 1) -3.6%,
					rgba(18, 54, 54, 0.742734593837535) 85.8%
				);
				filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#123636",endColorstr="#123636",GradientType=1);
				content: "";
				height: 100%;
				left: 0;
				position: absolute;
				top: 0;
				width: 100%;
			}
		}

		&.full-height {
			height: 100%;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	&__col-icon {
		margin-bottom: 1.5rem;
		position: relative;
	}

	&__col-title {
		@extend %o-title-inter-semibold-36;
		color: $color-white-1;
		line-height: 1.2;
		margin-bottom: 1.5rem;
		margin-top: 0;
		position: relative;
	}

	&__col-text {
		position: relative;

		p {
			color: $color-white-1;
			font-size: 1.8rem;
		}
	}
}
